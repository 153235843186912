import React, { useEffect, useState } from 'react'
import Card from 'antd/es/card/Card'
import { Descriptions } from 'antd'
import { Typography } from '@mui/material'
import ViewAuditorRequestForm from '../../WizardFlow/Forms/AuditorRequestForm/View'
import ExternalAccessExtensionRequestView from '../../WizardFlow/Forms/ExternalEmployeeExtension/View'
import ViewExternalEmployeeRequest from '../../WizardFlow/Forms/ExternalRequestForm/View'
import InstantMessagingView from '../../WizardFlow/Forms/InstantMessaging/View'
import USBAccessView from '../../WizardFlow/Forms/USBAccessRequestForm/View'
import SoftwareInstallView from '../../WizardFlow/Forms/SoftwareInstallForm/View'
import MailTransferView from '../../WizardFlow/Forms/MailTransferForm/View'
import ShareDocExternalView from '../../WizardFlow/Forms/ShareDocExternalForm/View'
import LaptopAdminAccessView from '../../WizardFlow/Forms/LaptopAdminAccessForm/View'

// Based on Component, Give the View. If Form Does not have sepecific view, default view is given
export default function ViewRouter({requestData, parameters}) {

    let component_name = requestData.component_detail.component_name
    switch(component_name) {
        case "AUDITOR_REQUEST_FORM": 
            return <ViewAuditorRequestForm requestData={requestData} parameters={parameters} />
        case "ACCESS_EXTENSION_REQUEST":
            return <ExternalAccessExtensionRequestView requestData={requestData} parameters={parameters} />
        case "EXTERNAL_REQUEST_FORM":
            return <>
                <ViewExternalEmployeeRequest requestData={requestData} parameters={parameters} /> 
            </>
        case "INSTANT_MESSAGING_FORM":
            return <>
            <InstantMessagingView requestData={requestData} parameters={parameters} /> 
        </>
        case "USB_ACCESS":
            return <>
            <USBAccessView requestData={requestData} parameters={parameters} />
        </>
        case "SOFTWARE_INSTALL":
            return <>
            <SoftwareInstallView requestData={requestData} parameters={parameters} />
        </>
        case "MAIL_TRANSFER":
            return <>
            <MailTransferView requestData={requestData} parameters={parameters} />
        </>
        case "SHARE_DOC_TO_EXTERNAL":
            return <>
            <ShareDocExternalView requestData={requestData} parameters={parameters} />
        </>

        case "LAPTOP_ADMIN_ACCESS":
            return <>
            <LaptopAdminAccessView requestData={requestData} parameters={parameters} />
        </>

        default:
            return <DefaultFormView parameters={parameters}/>
    }
}


function DefaultFormView({parameters}){
    let ListItems = []
    for (let value in parameters) {
        ListItems.push(
            {
                key: value,
                label: value,
                span: 5,
                children: parameters[value],
            }
        )
    }

    return (
        <Card
        title={<Typography sx={{fontSize: 16, fontWeight: 600}}>Request Data</Typography>}
        >
            <Descriptions title="" layout="horizontal" bordered items={ListItems} />
        </Card>
    )
}