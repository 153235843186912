import { Grid, Typography } from '@mui/material'
import { Avatar, Card } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function FormCard({access, index}) {
    const navigate = useNavigate();

  return (
    <>
        <Card
            onClick={() => navigate(`/create-request/${access.route}`, { state: { form: access.route } })}
            style={{height: '100%'}}
            hoverable bordered={false} 
            // style={{width: '100%', }}
            >
            <Grid container spacing={6}>
                <Grid item xs={2} sx={{textAlign: 'left'}}>
                    <Avatar 
                        shape="square"
                        size={40} 
                        src={access.icon}
                        placement="center" 
                    />
                </Grid>
                <Grid item xs={8} sx={{textAlign:'left'}}>
                    <Typography variant='h5' sx={{fontSize: 14, fontWeight: 600}}>{access.application_title}</Typography>
                    <Typography variant='body1' sx={{fontSize: 12, fontWeight: 400}}>
                        {access.description}
                    </Typography>
                </Grid>

            </Grid>
        </Card>
    </>
  )
}
