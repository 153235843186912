import React, { useEffect, useState } from 'react'
import Card from 'antd/es/card/Card'
import { Typography, Grid, Box, Paper } from '@mui/material'
import TextArea from 'antd/es/input/TextArea'
import { Form, Input, Select, DatePicker, Space, Button, Radio, AutoComplete } from "antd"
import { notification } from "antd"
import { gendersAll, bloodGroups, phonePeDomains, phonePeEntities, accessList, companies } from '../../../../../Utils/constants'
import SearchUser from '../../../Components/Global/SearchUser'
import { useOutletContext } from "react-router-dom";
import { EndDateValid, createFlashRequest, disabledDate, disabledDateTime, flashDataFetcher, validateEmail } from '../../../../../Utils/flashHelpers';
import { DynamicRequestorSelect } from '../../../Components/Global/DynamicRequestor'
import AccessRequestConfirmationDialog from '../../Confirmation/AccessRequestConfirmationDialog'


export default function CreateExternalUserRequest() {
    const [form] = Form.useForm();
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();
    const [triggerSubmission, setTriggerSubmission] = useState(false);
    const [values, setValues] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setValues(values);
        setTriggerSubmission(true);
        setLoading(false);
    }   

    return (
        <Grid container spacing={2}>
            {contextHolder}
            <Grid  item xs={12} sm={10} md={10} lg={10} xl={10} xxl={10}>
                <Card
                    title="External Employee Onboarding Form"
                >
                {triggerSubmission ? (
                    <AccessRequestConfirmationDialog
                        triggerSubmission={triggerSubmission}
                        setTriggerSubmission={setTriggerSubmission}
                        request_title={"External User Onboarding"}
                        entity_type={"CREATE"}
                        component_name={"EXTERNAL_REQUEST_FORM"}
                        state={"created"}
                        parameters={values}
                        requestor={loginUser.user_email}
                    />
                    ) : (
                    <></>
                    )}
                    <Form
                        onFinish={onFinish}
                        layout="vertical"
                        initialValues={{
                            DYNAMIC_REQUESTOR: loginUser.user_email,
                        }}
                    >
                                                       {
                                    <DynamicRequestorSelect />
                                }
                                {
                                    <UserInformation />

                                }
                                {
                                    <CompanyDetails/>
                                }
                                {
                                    <AgencyDetails />
                                }
                                {
                                    <ScopeofWork />
                                }
                                {
                                    <BusinessJustification />
                                }
                        <Form.Item>
                            <Box sx={{ mt: 4, textAlign: "right" }}>
                                    <Button
                                        disabled={loading}
                                        htmlType="submit"
                                        style={{ width: 200, height: 40 }}
                                        type="primary"
                                    >
                                        {loading ? (
                                        <Spin />
                                        ) : (
                                        <Typography sx={{ fontWeight: 600 }}>
                                            Submit & Request
                                        </Typography>
                                        )}
                                    </Button>
                                </Box>
                        </Form.Item>
                    </Form>
                </Card>

            </Grid>
        </Grid>
    )

}



function UserInformation() {
    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                User Information
            </Typography>}
        >
            <Grid container spacing={1}>
                <Grid item sm={12} xs={12} md={4} lg={4}>
                    <Box>
                        <Form.Item
                            name="FIRST_NAME"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                First Name
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                    message: 'First Name cannot be empty!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={4} lg={4}>
                    <Box>
                        <Form.Item
                            name="LAST_NAME"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Last Name
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Last Name cannot be empty!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={4} lg={4}>
                    <Box>
                        <Form.Item
                            name="FULL_NAME"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Full Name
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Full Name cannot be empty!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                        <Form.Item
                            name="GENDER"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Gender
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Gender cannot be empty!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select Gender"
                                style={{
                                    width: '100%',
                                }}
                                options={gendersAll}
                            >

                            </Select>
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                        <Form.Item
                            name="BLOOD_GROUP"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Blood Group
                            </Typography>}
                        >
                            <Select
                                showSearch
                                placeholder="Blood Group"
                                style={{
                                    width: '100%',
                                }}
                                options={bloodGroups}
                            >

                            </Select>
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                        <Form.Item
                            name="AGENCY_EMAIL_ADDRESS"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Agency Email Address
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Email Address cannot be empty!',
                                },
                                {
                                    required: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    min: 5,
                                    message: "'Email' must be between 5 and 255 characters.",
                                },
                                {
                                    validator: validateEmail,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                        <Form.Item
                            name="AGENCY_PHONE_NUMBER"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Phone Number
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Phone Number cannot be empty!',
                                },
                                {
                                    min: 10,
                                    message: "Phone Number must be 10 Digits.",
                                },
                                {
                                    max: 10,
                                    message: "Phone Number must be 10 Digits.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    )
}

function CompanyDetails({userManager, setUserManager}) {
    const [selectedOption, setSelectedOption] = useState()

    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                Company Information
            </Typography>}
        >
            <Grid container spacing={1}>
                <Grid item sm={12} xs={12} md={3} lg={3}>
                    <Box>
                        <Form.Item
                            name="START_DATE"
                            
                            rules={[
                                {
                                    required: true,
                                    message: 'Start Date cannot be empty!',
                                },
                                
                                // {
                                //     validator: validateStartDate
                                // }
                            ]}
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Start Date
                            </Typography>
                            }
                        >
                            <DatePicker
                                    format="YYYY-MM-DD"
                                    disabledDate={disabledDate}
                                    disabledTime={disabledDateTime}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={3} lg={3}>
                    <Box>
                    <Form.Item
                            name="END_DATE"
                            rules={[
                                {
                                    required: true,
                                    message: 'End Date cannot be empty!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject('End Date is required!');
                                        }
                                        const startDate = getFieldValue('START_DATE');
                                        if (!startDate) {
                                            return Promise.reject('Please select a Start Date first!');
                                        }

                                        const maxEndDate = startDate.clone().add(1, 'year');
                                        if (value.isAfter(maxEndDate)) {
                                            return Promise.reject('End Date must be within one year of the Start Date!');
                                        }

                                        if (value.isBefore(startDate)) {
                                            return Promise.reject('End Date cannot be before the Start Date!');
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                End Date
                            </Typography>}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                disabledDate={disabledDate}
                                disabledTime={disabledDateTime}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                        <Form.Item
                            name="PHONEPE_ENTITY"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                PhonePe Entity
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                    message: 'PhonePe Entity cannot be empty!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="PhonePe Entity"
                                style={{
                                    width: '100%',
                                }}
                                options={phonePeEntities}
                            >

                            </Select>
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                        <SearchUser
                            //   selectedOption={userManager}
                            //   setSelectedOption={setUserManager}
                            FieldName={"PHONEPE_MANAGER"}
                            FieldLabel={"Who will be the manager?"}
                        />
                    </Box>
                </Grid>

                <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                        <Form.Item
                            name="EMAIL_DOMAIN"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Which Email Domain you want?
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Domain"
                                style={{
                                    width: '100%',
                                }}
                                options={phonePeDomains}
                            >

                            </Select>
                        </Form.Item>
                    </Box>
                </Grid>

            </Grid>


        </Card>
    )
}

function AgencyDetails() {

    const [options, setOptions] = useState([])

    const handleSearch = async (value) => {
        if (value) {
        if (value.length > 2) {
            flashDataFetcher(`v1/ums/contracts?search_key=${value}`).then((data) => {
              createOptions(data)
            }).catch((error) => {
              console.log(`Error - ${error}`)
            })
          }
        }
      };

      const createOptions = (options) => {
        let data = []
        options?.map((contract) => {
          data.push({
            label: (
              <Paper sx={{p:1}} variant="outlined">
                <Typography sx={{ fontSize: 12 , fontWeight: 500}}>{contract}</Typography>
              </Paper>
            ),
            value: contract
          })
        })
        setOptions(data)
      }

    return (
        <>
            <Card
                style={{ marginTop: 20 }}
                title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                    Agency Details
                </Typography>}
            >
                <Grid container spacing={2}>

                    <Grid item sm={12} xs={12} md={12} lg={12}>
                        <Box>
                            <Form.Item
                                name="AGENCY_NAME"
                                label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                    Select Agency
                                </Typography>}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <AutoComplete
                                    options={options}
                                    style={{
                                    width: '100%',
                                    }}
                                    // defaultValue={}
                                    onSearch={handleSearch}
                                    // onSelect={onSelect}
                                    placeholder={"Search Agency"}
                                />
                            
                            </Form.Item>
                        </Box>
                    </Grid>


                </Grid>
            </Card>

        </>
    )
}

function ScopeofWork() {
    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                Scope of Work
            </Typography>}
        >
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name="LIST_OF_ACCESSES_NEEDED"
                        label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                            List of Accesses Needed
                        </Typography>}
                    // rules={[
                    //   {
                    //     required: true,
                    //   },
                    // ]}
                    >
                        <Select
                            mode="multiple"
                            // defaultValue={}
                            style={{
                                width: '100%',
                            }}
                            options={accessList}
                        />
                    </Form.Item>
                </Grid>

            </Grid>
        </Card>
    )
}

function BusinessJustification() {
    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                Business Justification
            </Typography>}
        >
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Box>
                        <Form.Item
                            name="JUSTIFICATION"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Business Justification
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea
                                placeholder="Provide Justification for all mentioned accesses above."
                                autoSize={{
                                    minRows: 4,
                                    maxRows: 6,
                                }}
                            />
                        </Form.Item>
                    </Box>
                </Grid>

            </Grid>
        </Card>
    )
}