import React, {useEffect, useState} from 'react'
import { Box, Typography } from '@mui/material'
import { useOutletContext } from "react-router-dom";
import Card from 'antd/es/card/Card';
import TextArea from 'antd/es/input/TextArea';
import { Button, notification } from 'antd';
import { createFlashDataRequest } from '../../../../../Utils/flashHelpers';
import { flashDataFetcher } from '../../../../../Utils/flashHelpers';
import { useParams } from 'react-router-dom';

export default function ApprovalBox({ approversMatrix, getApproversMatrix, isApproverMatrix, refreshData }) {

    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();
    const [comment, setComment] = useState()
    const [api, contextHolder] = notification.useNotification();
    const [processingApproval, setProcessingApproval] = useState(false)

    
    const awaitingApprovalAssignments = approversMatrix.filter((assignment) => {
        return assignment.status === "AWAITING_APPROVAL"
    })


    const approverList = awaitingApprovalAssignments.length > 0 ? awaitingApprovalAssignments[0].assigned_to : []

    useEffect(() => {

        getApproversMatrix()
        refreshData()
    }, [processingApproval])


    async function approveTransition() {
        setProcessingApproval(true)
        let data = {
            "request_uuid": awaitingApprovalAssignments[0].request_uuid,
            "transition_uuid": awaitingApprovalAssignments[0].assignment_uuid,
            "comment": comment ? comment : null
        }

        createFlashDataRequest('request/approve/STORK', data).then((data) => {

            if (data.status === "success") {
                api.success({
                    message: "Approval Recorded",
                    description: "Your approval has been recorded"
                })
               
                setProcessingApproval(false)
            } else {
                api.error({
                    message: 'Error Happened',
                    description: `${data.message}`,
                });
                setProcessingApproval(false)
            }
        }).catch((error) => {
            if (error.toString().toLowerCase().includes('too many requests')){
                api.error({
                    message: 'Too Many Requests',
                    description: `Please, wait for some more seconds!`,
                });
            }
            setProcessingApproval(false)
        })
    }

    async function rejectTransition() {
        setProcessingApproval(true)

        let data = {
            "request_uuid": awaitingApprovalAssignments[0].request_uuid,
            "transition_uuid": awaitingApprovalAssignments[0].assignment_uuid,
            "comment": comment ? comment : null
        }
        createFlashDataRequest('request/reject/STORK', data).then((data) => {
            if (data.status === "success") {
                api.success({
                    message: "Rejection Recorded",
                    description: "Your rejection has been recorded. You have rejected this request."
                })
                setProcessingApproval(false)
            } else {
                api.error({
                    message: 'Error Happened',
                    description: `${data.message}`,
                });
                setProcessingApproval(false)

            }
        })
    }

    return (
        <Box>
            {contextHolder}
            {
            approverList.includes(loginUser.user_email) ?
            <Card sx={{ mt: 4, p: 2 }}>
                <Typography variant="p" sx={{ fontSize: 14, fontWeight: 600 }}>
                    The request requires your approval.
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <TextArea
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Leave a comment (Optional)" allowClear />
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Button
                        loading={processingApproval} 
                        onClick={() => approveTransition()} 
                        type='primary'
                        size='large' style={{
                            width: "100%",
                        }}>Approve</Button>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Button
                        loading={processingApproval} 
                        onClick={() => rejectTransition()} 
                        type="primary" size='medium' danger style={{
                            width: "100%"
                        }}>Reject</Button>
                </Box>
            </Card>
            : <></>
            }
        </Box>
    )
}
