import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Box, Grid, Backdrop, CircularProgress, Typography } from '@mui/material';
import BreadCrumb from '../../Components/BreadCrumb';
import { flashDataFetcher } from '../../../../Utils/flashHelpers';
import ViewRouter from './ViewRouter';
import RequestInformation from './Components/RequestInformation';
import TransitionsList from './Components/TransitionsList';
import Card from 'antd/es/card/Card';
import ApprovalBox from './Components/ApprovalBox';
import Comments from './Components/Comments';
import { useOutletContext } from "react-router-dom";
import { Button, Result, Spin, notification } from 'antd';
import RejectRequestButton from '../../../Components/RejectRequestButton';
// CopyOutlined
import { CopyOutlined } from '@ant-design/icons';

export default function ViewRequest({ request_uuid }) {
    const [api, contextHolder] = notification.useNotification();

    let { id } = useParams();

    if (request_uuid) {
        id = request_uuid
    }

    // Function to copy request link
    function copyRequestLink() {
        // Create a temporary input element
        // console.log()
        if (requestData && requestData.request_uuid) {
            const input = document.createElement('input');
            input.value = `${process.env.REACT_APP_UI_BASE_URL}request/view/${requestData.request_uuid}`;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            api.success({
                message: "Copied to Clipboard",
                description: `Link has been copied to Clipboard`,
            });
        }
    }

    // Request Paramters
    const [loading, setLoading] = useState(false)
    const [requestData, setRequestData] = useState()
    const [commentsData, setCommentsData] = useState([])
    const [commentsLoading, setCommentsLoading] = useState(true)
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();


    // Refesh Request Data if needed
    async function updateRequestData() {
        flashDataFetcher(`request/STORK/${id}`).then((data) => {
            if (data.status === "success") {
                if (data.data.length > 0) {
                    setRequestData(data.data[0])
                }
            }
        }).catch((error) => {
            console.log(`Error Happened - ${error}`)
        })
    }

    // Load Comments Data
    async function loadCommentsData() {
        flashDataFetcher(`comments/STORK/${id}`)
            .then(
                (result) => {
                    if ("data" in result) {
                        setCommentsData(result.data);
                        setCommentsLoading(false)
                    } else {
                        console.log(`error`, result);
                    }
                },
            )
            .catch(
                (error) => {
                    console.log(`error`, error);
                    // setUserDataLoaded(true);
                    // setUsersError(error.message);
                    if (error.message.includes("Unauthenticated") || error.message.includes("Not Enough Permissions")) {
                        handleTokenError(error.message);
                    }
                }
            )
    }

    // Fetch Request Data
    async function fetchRequestData() {
        setLoading(true)
        flashDataFetcher(`request/STORK/${id}`).then((data) => {
            if (data.status === "success") {
                if (data.data.length > 0) {
                    setRequestData(data.data[0])
                    setLoading(false)
                }
            } else if (data.status === "failure") {
                setLoading(false)
            }
        }).catch((error) => {
            console.log(`Error Happened - ${error}`)
            setLoading(false)
        })
    }


    // Fetch Approval Data
    const [approversMatrix, setApproversMatrix] = useState()
    const [isApproverMatrix, setisApproverMatrix] = useState(false)
    async function getApproversMatrix() {
        flashDataFetcher(`request/matrix/STORK/${id}`).then((data) => {
            if (data.status === "success") {
                if (data.data.length > 0) {
                    setApproversMatrix(data.data)
                    setisApproverMatrix(true)
                } else {
                    setisApproverMatrix(false)
                }
            }
        }).catch((error) => {
            console.log(`Error Happened - ${error}`)
        })
    }

    useEffect(() => {

        fetchRequestData()
        getApproversMatrix()

    }, [])

    async function refreshData() {
        updateRequestData()
        loadCommentsData()

    }

    return (
        <Box sx={{ p: {xs: 1, sm: 0, md: 0, lg: 4, xl: 4, xxl: 4} }}>
            {
                contextHolder
            }
            {
                !loading && !requestData ?
                    <>
                        <Result
                            status="error"
                            title={<Typography>Request Not Found!</Typography>}

                        />
                    </>
                    : <></>
            }
            {
                loading ?
                    <Box sx={{ textAlign: 'center', alignContent: 'center', mt: 10 }}>
                        <Spin />
                    </Box>
                    :
                    <>
                        
                        <Box sx={{ textAlign: 'right', mt:4 }}>
                            <Button style={{marginRight: 4}} onClick={() => copyRequestLink()} type="primary" info>
                                <Typography sx={{fontSize: 14, fontWeight: 600}}><CopyOutlined /> Copy Link</Typography>
                            </Button>
                            {
                                requestData ?
                                    <RejectRequestButton
                                        request_uuid={id}
                                        refreshData={refreshData}
                                        getApproversMatrix={getApproversMatrix}
                                    /> : <></>
                            }

                        </Box>

                        <Box sx={{ mt: 4 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                    <Box>
                                        {
                                            requestData ?
                                                <ViewRouter
                                                    requestData={requestData ? requestData : []}
                                                    parameters={requestData ? requestData.parameters : []}
                                                /> : <></>
                                        }
                                    </Box>
                                    <Box sx={{ mt: 2 }}>
                                        {
                                            requestData ?
                                                <Comments
                                                    request_uuid={id}
                                                    commentsData={commentsData}
                                                    loading={commentsLoading}
                                                    loadCommentsData={loadCommentsData} />
                                                : <></>
                                        }

                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                    <Box>
                                        {requestData ? <RequestInformation request={requestData} /> : ''}
                                    </Box>
                                    <Box sx={{ mt: 2 }}>
                                        {
                                            approversMatrix ?
                                                <ApprovalBox
                                                    refreshData={refreshData}
                                                    approversMatrix={approversMatrix}
                                                    getApproversMatrix={getApproversMatrix}
                                                    isApproverMatrix={isApproverMatrix}
                                                />
                                                : <></>
                                        }
                                    </Box>
                                    <Box sx={{ mt: 4 }}>
                                        {
                                            requestData ?
                                                <Card
                                                    title={"Request Approvers"}
                                                >
                                                    <TransitionsList
                                                        approversMatrix={approversMatrix}
                                                        getApproversMatrix={getApproversMatrix}
                                                        isApproverMatrix={isApproverMatrix}
                                                    />
                                                </Card>
                                                : <></>
                                        }

                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
            }
        </Box>
    )
}
