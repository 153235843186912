import React, { useEffect, useState } from 'react'
import { Card, Descriptions } from 'antd'
import { Box, Grid, Typography } from '@mui/material'
import { RequestStatusBadge, timeAgo } from '../../../../../Utils/flashHelpers'
import QuickViewUser from '../../../Components/Global/QuickViewUser'
import RequestAuditLogs from './AuditLogs'

export default function RequestInformation({ request }) {


  return (
    <Card bordered={false} shadowed={true}>
      <Box>
        {/* <Box sx={{textAlign:'right'}}>
          {
            request.status === ""
          }
          <Button size="medium" type="primary" danger>
            <Typography sx={{fontSize: 14, fontWeight: 600}}>Cancel Request</Typography>
          </Button>
          
        </Box> */}
      <Box sx={{ mb: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "gray" }}>
                  CHANGE TYPE
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "black" }}>
                  {request.entity_type}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "gray" }}>
                  REQUEST UUID
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "black" }}>
                  {request.request_uuid}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "gray" }}>
                  REQUEST RAISED FOR
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "black" }}>
                  {request.requestor}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "gray" }}>
                  REQUEST RAISED BY
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "black" }}>
                  {request.requested_by}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "gray" }}>
                  REQUEST STATUS
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "black" }}>
                  {RequestStatusBadge(request.status)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "gray" }}>
                  REQUESTED
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontSize: 14, color: "black" }}>
                  {timeAgo(request.stamp_created)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}
