import React, { useState } from "react";
import { Link as RouteLink } from "react-router-dom";
import {
    Toolbar, List, Typography, Divider, IconButton,
    ListItem, ListItemIcon, ListItemText, Tooltip,
    useTheme, styled, Collapse, Link,
    SwipeableDrawer,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import PerfectScrollbar from 'react-perfect-scrollbar';
import GsuiteProfile from '../GsuiteProfile';
import { userNavigationItems } from "../../../Routes/CommonRoutes";

import { Container } from "@mui/material";
import newLogo from '../../../Assets/Images/logos/full_logo_no_bg_white.png';
import newLogoIcon from '../../../Assets/Images/logos/icon_color.svg';
import useMediaQuery from '@mui/material/useMediaQuery';


import { Box } from "@mui/material";
import { adminNavigationItems } from "../../../Routes/AdminRoutes";
import zIndex from "@mui/material/styles/zIndex";

const drawerWidth = '260px';

const variant = {
    xs: 'temporary', // Mobile
    sm: 'temporary', // Tablet
    md: 'permanent', // Desktop
};

const Drawer = styled(SwipeableDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            // overflowX: 'hidden',
            // position: 'relative',
            // // zIndex: 9999,
            // whiteSpace: 'nowrap',
            // width: drawerWidth,
            // background: theme.palette.background.default,
            // color: theme.palette.text.primary,
            // borderRight: 'none',
            // // zIndex: "auto",
            // transition: theme.transitions.create('width', {
            //     easing: theme.transitions.easing.sharp,
            //     duration: theme.transitions.duration.enteringScreen,
            // }),
            // boxSizing: 'border-box',
            // ...(!open && {
            //     overflowX: 'hidden',
            //     transition: theme.transitions.create('width', {
            //         easing: theme.transitions.easing.sharp,
            //         duration: theme.transitions.duration.leavingScreen,
            //     }),
            //     width: 0,
            //     background: theme.palette.background.default,
            //     color: theme.palette.text.primary,
            //     borderRight: 'none',
            //     // top: '64px',
            //     [theme.breakpoints.up('sm')]: {
            //         width: theme.spacing(7),
            //     },
            // }),
        },
    }),
);

export default function SideBar({ loginUser,
    handleModeChange, handleLogout, title, open,
    setOpen, handleDrawerClose, handleDrawerOpen }) {

    const theme = useTheme();


    let navigationItems = userNavigationItems;

    if (loginUser.scopes.includes("stork.hr_read") || loginUser.scopes.includes("stork.stork_admin")) {
        navigationItems = adminNavigationItems;
    }

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const drawerVariant = isDesktop ? variant.md : variant.xs;

    return (
        <>
            <SwipeableDrawer
                PaperProps={{
                    sx: {
                        background: theme.palette.background.default,
                        width: drawerWidth,
                        position: 'relative',
                        overflowX: 'hidden',

                        ...(!open && {
                            overflowX: 'hidden',
                            transition: theme.transitions.create('width', {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.leavingScreen,
                            }),
                            width: 0,
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none',
                            // top: '64px',
                            [theme.breakpoints.up('sm')]: {
                                width: theme.spacing(7),
                            },
                        }),
                    }
                }}
                variant={drawerVariant}
                open={open}
                onClose={handleDrawerClose}
                sx={{
                    zIndex: 1,

                }}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Box sx={open ? { m: 1, mt: 4 } : { m: 0 }}>

                    {
                        open ?
                            <Box sx={{ textAlign: 'center' }}>
                                <Link href="/">
                                    <img height="60vh" src={theme.palette.mode === "dark" ? newLogo : newLogo} alt="UMS" />
                                </Link>
                            </Box>
                            : <></>
                    }
                    <PerfectScrollbar component="div" style={{
                        height: 'calc(100vh - 88px)',
                        overflow: 'hidden'
                    }} >
                        {/* Internal Links */}
                        <Box sx={{}}>
                            {
                                Object.keys(navigationItems).map((groupTitle, index) =>
                                    <React.Fragment key={groupTitle}>
                                        <List key={groupTitle}
                                            subheader={
                                                <Collapse in={open}>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{ ...theme.typography.menuCaption, fontWeight: 400 }}
                                                        display="block" gutterBottom ml={1} mr={1}>
                                                        {index === 0 ? "" : groupTitle.toUpperCase()}
                                                    </Typography>
                                                </Collapse>
                                            }
                                        >
                                            {Object.keys(navigationItems[groupTitle]).map(slug => (
                                                <ListItem key={slug}
                                                    to={navigationItems[groupTitle][slug].link}
                                                    component={RouteLink}
                                                    sx={{
                                                        background: title === slug && "linear-gradient(289deg, #0064F9 4.82%, rgba(0, 66, 163, 0.96) 95.39%)",
                                                        borderRadius: '6px',
                                                        ':hover': {
                                                            background: "linear-gradient(289deg, #0064F9 4.82%, rgba(0, 66, 163, 0.96) 95.39%)",
                                                        },
                                                        my: 2
                                                    }} >
                                                    <Tooltip title={navigationItems[groupTitle][slug].title} placement="right" arrow disableInteractive>
                                                        <ListItemIcon sx={{ minWidth: '40px', color: "#fff" }} >
                                                            {navigationItems[groupTitle][slug].icon}
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText
                                                        primary={<Typography variant="body2" style={{ color: '#F1F5F2', fontWeight: 600 }}>
                                                            {navigationItems[groupTitle][slug].title}</Typography>}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                        <Divider sx={{ mt: 0.25, mb: 1.25, mx: 2, color: '#ffff' }} />
                                    </React.Fragment>
                                )
                            }
                        </Box>

                    </PerfectScrollbar>
                </Box>
            </SwipeableDrawer>
        </>
    );
}

