import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Card, Divider } from 'antd';
import QuickViewUser from '../../../Components/Global/QuickViewUser';


export default function MailTransferView({ requestData, parameters }) {
  return (
    <Card title="Mail Transfer Request Details">
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Request Raised For</Typography>
            <Box sx={{ mt: 1 }}>
              <QuickViewUser user_id={requestData?.parameters.DYNAMIC_REQUESTOR} />
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>New Email Address</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.NEW_EMAIL_ADDRESS}
            </Box>
          </Grid> <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Previous Email Address</Typography>
            <Box>
              {requestData?.parameters.PREVIOUS_EMAIL_ADDRESS}
            </Box>

          </Grid>

        </Grid>
        <Divider />
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Confirmation</Typography>
            <Box sx={{ mt: 1 }}>
              {
                requestData?.parameters.CONFIRMATION === "CONFIRMATION_FOR_TRANSFER_TO_PERSONAL_EMAIL" ?
                <Typography sx={{ fontSize: 14 }}><strong>No,</strong> Corporate sensitive data is being transferred in case of transfer to personal mail ids.</Typography> : <></>
              }
                            {
                requestData?.parameters.CONFIRMATION === "CONFIRMATION_FOR_TRANSFER_TO_NEW_JOINER" ?
                <Typography  sx={{ fontSize: 14 }}><strong>Yes,</strong> Transfer all emails and data, including corporate sensitive information, to the new Joiner's' email </Typography> : <></>
              }
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Justification</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.JUSTIFICATION}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
