import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Card, Divider } from 'antd';
import QuickViewUser from '../../../Components/Global/QuickViewUser';

export default function LaptopAdminAccessView({ requestData, parameters }) {
  return (
    <Card title="Request for administrator permission for Laptop">
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Request Raised For</Typography>
            <Box sx={{ mt: 1 }}>
              <QuickViewUser user_id={requestData?.parameters.DYNAMIC_REQUESTOR} />
            </Box>
          </Grid>
          <Divider/>
          </Grid>
          <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Host Name</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.HOST_NAME}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>End Date of Access</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.END_DATE}
            </Box>
          </Grid>
          <Divider/>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Justification</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.JUSTIFICATION}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
