import React, { useEffect, useState } from 'react';
import Card from 'antd/es/card/Card';
import { Typography, Grid, Box } from '@mui/material';
import TextArea from 'antd/es/input/TextArea';
import { Form, Input, Select, DatePicker, Space, Button, Radio, Checkbox, Spin } from "antd";
import { notification } from "antd";
import { gendersAll, bloodGroups, phonePeDomains, phonePeEntities, USBaccessList, companies } from '../../../../../Utils/constants';
import SearchUser from '../../../Components/Global/SearchUser';
import { useOutletContext } from "react-router-dom";
import { EndDateValid, createFlashRequest, disabledDate, disabledDateTime, validateEmail } from '../../../../../Utils/flashHelpers';
import { DynamicRequestorSelect } from '../../../Components/Global/DynamicRequestor';
import AccessRequestConfirmationDialog from '../../Confirmation/AccessRequestConfirmationDialog';

export default function CreateSoftwareInstallRequest() {
    const [form] = Form.useForm();
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();
    const [triggerSubmission, setTriggerSubmission] = useState(false);
    const [values, setValues] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);


    const onFinish = (values) => {

        setValues(values);
        setTriggerSubmission(true);
        setLoading(false);
    }


    return (
        <Grid container spacing={2}>
            {contextHolder}
            <Grid item xs={10}>
                <Card title="Software/Plugins Installation Request">
                    {triggerSubmission ? (
                        <AccessRequestConfirmationDialog
                            triggerSubmission={triggerSubmission}
                            setTriggerSubmission={setTriggerSubmission}
                            request_title={"Software/Plugins Installation Request"}
                            entity_type={"CREATE"}
                            component_name={"SOFTWARE_INSTALL"}
                            state={"created"}
                            parameters={values}
                            requestor={values ? values.DYNAMIC_REQUESTOR : null}
                        />
                    ) : null}
                    <Form
                        onFinish={onFinish}
                        layout="vertical"
                        initialValues={{
                            DYNAMIC_REQUESTOR: loginUser.user_email,
                        }}
                    >
                     {<DynamicRequestorSelect />}
                    
                        <ScopeofWork />
                        <BusinessJustification />

                        <Form.Item>
                            <Box sx={{ mt: 4, textAlign: "right" }}>
                                <Button
                                    disabled={loading}
                                    htmlType="submit"
                                    style={{ width: 200, height: 40 }}
                                    type="primary"
                                >
                                    {loading ? (
                                        <Spin />
                                    ) : (
                                        <Typography sx={{ fontWeight: 600 }}>
                                            Submit & Request
                                        </Typography>
                                    )}
                                </Button>
                            </Box>
                        </Form.Item>
                    </Form>
                </Card>
            </Grid>
        </Grid>
    );
}


function ScopeofWork() {
    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                Scope of Work
            </Typography>}
        >
            <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                    <Form.Item
                        name="SOFTWARE_DETAILS"
                        rules={[
                            {
                                required: false,
                                message: 'Software details cannot be empty!',
                            },
                        ]}
                        label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                            Software Details
                        </Typography>}
                    >
                        <Input
                            placeholder="Enter Software details"
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>

                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                       <Form.Item
                            name="PLUGINS_DETAILS"
                            rules={[
                                {
                                    required: false,
                                    message: 'Plugin details cannot be empty!',
                                },
                            ]}
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Plugins Details
                            </Typography>}
                        >
                            <Input
                                placeholder="Enter Plugins details"
                                style={{
                                    width: '100%',
                                }}
                            />
                       </Form.Item>

                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}

function BusinessJustification() {
    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                Business Justification
            </Typography>}
        >
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Box>
                        <Form.Item
                            name="JUSTIFICATION"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Business Justification
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea
                                placeholder="Provide Justification for all mentioned accesses above."
                                autoSize={{
                                    minRows: 4,
                                    maxRows: 6,
                                }}
                            />
                        </Form.Item>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}