import React, { useEffect, useState } from 'react'
import { useOutletContext } from "react-router-dom";
import useSWR from 'swr'
import Card from 'antd/es/card/Card'
import { Grid, Box, Typography } from '@mui/material'
import { Empty, Button, Spin } from 'antd';
import { flashDataFetcher } from '../../../../Utils/flashHelpers';
import RequestCard from '../RequestCard';
import { Backdrop, CircularProgress } from '@mui/material';
import Filters from '../../../../Utils/Filters';


export default function MyRequests() {
    const [allRequests, setAllRequests] = useState([])
    const [loading, setLoading] = useState(true)
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();


    const [pagination, setPagination] = useState({
        count: 1,
        page: 0,
        limit: 0,
        rowsPerPage: 6,
        data: [],
        isLoading: true,
        isSearching: false
    });

    const [filters, setFilters] = useState({
        request_uuid: {
            type: 'text',
            value: '',
            operator: 'exact',
            allowed_operators: ['exact'],
        }
    });
    
    // v1/requests/tenant/
    const BaseUrl = `requests/STORK?sort_order=desc&requested_by=${loginUser.user_email}`
    const urlPagination = `&skip=${pagination.page * pagination.rowsPerPage}&limit=${pagination.rowsPerPage}`;
    const urlFilterByRequestUUID = filters.request_uuid.value && `&request_uuid=${filters.request_uuid.value}`;
    const url = BaseUrl + urlPagination + urlFilterByRequestUUID 
    const { data: requests, error: requestsErorrs, mutate: refreshRequestsData } = useSWR(url, flashDataFetcher);



    const handleFilterUpdate = (filterType, value, key = 'value') => {
        let update = { ...filters };
        if (key === 'value') {
            if (filterType === 'tags') {
                update[filterType].value = value.toUpperCase();
            } else {
                update[filterType].value = value;
            }
        } else if (key === 'operator') {
            update[filterType].operator = value;
        }
        setFilters(update);
        setPagination({
            ...pagination,
            page: 0,
            rowsPerPage: 100,
            isSearching: true
        });
        setLoading(true)
    }


    useEffect(() => {
        // setLoadMore(true)
        if (requests) {
            if (requests.status == "success") {
                setPagination({
                    ...pagination,
                    count: (requests && requests.pagination.total) || 1,
                    data: !pagination.isSearching ? [...pagination.data, ...requests.data] : requests.data,
                    isLoading: false
                });
                setLoading(false)
            } else {
                setPagination({
                    ...pagination,
                    isLoading: false
                });
                setLoading(false)

            }
        }

    }, [requests])

    const changePage = (page) => {
        setPagination({
            ...pagination,
            isLoading: true,
            page: page
        });
    };

    return (
        loading ? 
            <Box sx={{textAlign:'center', mt:4}}><Spin /></Box>
        :
            <Box sx={{ mt: 5 }}>
                <Box sx={{ mb: 6 }}>
                    <Filters
                        filters={filters}
                        handleFilterUpdate={handleFilterUpdate}
                    />
                </Box>
                <Box>
                    {
                        pagination.data.length < 1 ?
                            <Box sx={{ mt: 6 }}>
                                <Empty
                                    imageStyle={{
                                        height: 200,
                                    }}
                                    description={
                                        <Typography variant="p">
                                            Nothing Found! Maybe things will happen soon.
                                        </Typography>
                                    }
                                />
                            </Box> : <></>
                    }
                </Box>

                <Grid container spacing={5}>
                    {
                        pagination.data.map((request, index) => {
                            return (
                                <Grid item xs={12} md={12} lg={6} xl={4} key={index}>
                                    <RequestCard
                                        request={request}
                                        current_tab={"ALL"}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>

                {
                    pagination.count == pagination.data.length ? <></> :
                    <Box
                        sx={{ mt: 4, mb: 4, textAlign: 'center' }} >
                        {
                            pagination.isLoading ? <Spin /> :
                        
                            pagination.data.length > 0 ?
                            <Button
                            size='large'
                            type='primary'
                            onClick={() => changePage(pagination.page + 1)} 
                            style={{
                                backgroundColor: 'black',
                                fontWeight: 600
                            }}
                        >
                            Load More
                        
                            </Button> :<></>
                        }
                    </Box>
                }
            </Box>
    )
}
