import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Flex, Radio } from 'antd';
import { ApplicationCategories } from '../../Requests/ComponentsListView/componentConstants';


export default function AppCategorySelect({selectedApplicationCategory, setSelectedApplicationCategory}) {

    const [size, setSize] = useState('medium'); // default is 'middle'
    
    
    // Function to manage application change

    function changeApplicationCategory (category_name) {
          setSelectedApplicationCategory(category_name)
    }
    
    return (
          <>
                <Flex style={{overflow: 'scroll'}} gap="small" wrap>
                      <Button 
                        type={selectedApplicationCategory === "ALL" ? "primary" : 'default'}
                        shape="round" 
                        size={size} 
                        onClick={() => setSelectedApplicationCategory("ALL")}
                        style={{ backgroundColor: selectedApplicationCategory === "ALL" ? "BLACK" : '' , fontWeight: 600 }}>
                              All Applications
                        </Button>
                      {
                            ApplicationCategories.map((app) => (
                                  <Button 
                                          type={selectedApplicationCategory === app.category_code ? "primary" : 'default'}
                                          shape="round" 
                                          size={size} 
                                          onClick={() => setSelectedApplicationCategory(app.category_code)}
                                          style={{ backgroundColor: selectedApplicationCategory === app.category_code ? "BLACK" : '' ,
                                          fontWeight: 600 }}>
                                        {app.category_name}
                                  </Button>
                            ))
                      }
                </Flex>
          </>
    )
}

