import { Button, Popconfirm, notification } from 'antd'
import React, { useState } from 'react'
import { createFlashDataRequest } from '../../Utils/flashHelpers'

export default function RejectRequestButton({ request_uuid, refreshData, getApproversMatrix }) {

    const [cancellingRequest, setCancellingRequest] = useState(false)

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [api, contextHolder] = notification.useNotification();

    const showPopconfirm = () => {
    setConfirmLoading(false);
      setOpen(true);
    };


    const handleOk = () => {
      setConfirmLoading(true);

      createFlashDataRequest(`request/cancel/${request_uuid}`).then((data) => {
        if (data) {
            if (data.status == "success") {
                api.success({
                    message: "Request Cancelled",
                    description: "Request Cancelled Successfully!"
                })
                refreshData()
                getApproversMatrix()
                setConfirmLoading(false);
                setOpen(false);
            } else {
                api.error({
                    message: "Failed to Cancel Request",
                    description: `${data.message}`
                })
                refreshData()
                getApproversMatrix()
                setConfirmLoading(false);
                setOpen(false);
            }
        }
      }).catch((error) => {
        api.error({
            message: "Failed to Cancel Request",
            description: `Failed with Error - ${error}`
        })
        refreshData()
        getApproversMatrix()
        setConfirmLoading(false);
        setOpen(false);
      })
    };


    const handleCancel = () => {
        setConfirmLoading(false);

      setOpen(false);
    };

  return (
    <>
    {contextHolder}
    <Popconfirm
      title="Cancel Request"
      description="Do you want to cancel the request?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{
        loading: confirmLoading,
      }}
      onCancel={handleCancel}
    >
        <Button type="primary" danger onClick={showPopconfirm}>
            <strong>CANCEL</strong>
        </Button>
    </Popconfirm>
    </>
  )
}
