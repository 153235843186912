import React, { useState } from 'react'
import { Card, Badge, Tag, Button } from 'antd'
import { Typography, Box, Paper, Grid } from '@mui/material'
import { ClockCircleOutlined } from '@ant-design/icons';
import { MessageOutlined } from '@ant-design/icons';
import { Col, Row, Statistic } from 'antd';
import { Progress } from 'antd';
import { timeAgo, calculatePercentage, RequestStatusBadge, getNameFromEmail } from '../../../Utils/flashHelpers';
import { useNavigate } from 'react-router-dom';
import QuickViewUser from '../Components/Global/QuickViewUser';
import FullScreenViewRequestView from '../WizardFlow/FullScreenViewRequestFlow/FullScreenViewRequestView';

const twoColors = {
    '0%': '#108ee9',
    '100%': '#87d068',
};

function ProgressBarStatus(state) {
    let status = "active"

    if (state.toUpperCase() === "DONE" || state.toUpperCase() === "APPLIED") {
        status = "active"
    } else if (state.toUpperCase() === "CANCELLED" || state.toUpperCase() === "REJECTED" || state.toUpperCase() === "FAILED") {
        status = "exception"
    }
    return status
}

function ProgressBarColors(state) {
    let color = {
        '0%': '#108ee9',
        '100%': '#87d068',
    }
    // let status = "active"

    if (state.toUpperCase() === "DONE" || state.toUpperCase() === "APPLIED") {
        color = {
            '0%': '#108ee9',
            '100%': '#87d068',
        }
    } else if (state.toUpperCase() === "CANCELLED" || state.toUpperCase() === "REJECTED" || state.toUpperCase() === "FAILED") {
        color = {

            '0%': '#b40000',
            '100%': '#ff0000',
        }
    }
    return color
}


export default function RequestCard({ request, current_tab }) {
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false)

    return (
        <>
            {
                openDialog ? <FullScreenViewRequestView setOpenDialog={setOpenDialog} openDialog={openDialog} request_uuid={request.unique_id} /> : ''
            }
           
                <Card
                    shadowed={false}
                    bordered={false}
                >
                    <Tag color="default" >
                        <Typography variant='p' sx={{ fontSize: 12, fontWeight: 600 }}>
                            {request.component_name.toUpperCase()}
                        </Typography>
                    </Tag>

                    <Box sx={{ mt: 5 }}>
                        {/* {request.unique_id} */}
                        <Typography sx={{ fontSize: 14, color: 'gray' }}>Request Title</Typography>
                        <Typography sx={{ fontSize: 16, color: 'black' }}>{request.request_title}</Typography>
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Grid container>
                            <Grid xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                <Typography sx={{ fontSize: 14, color: 'gray' }}>Requestor</Typography>
                                <Typography sx={{ fontSize: 16, color: 'black' }}>{getNameFromEmail(request.requested_by)}</Typography>
                            </Grid>
                            <Grid xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} sx={{ mt: {xs: 1, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0} }}>
                                <Typography sx={{ fontSize: 14, color: 'gray' }}>Requested</Typography>
                                <Typography sx={{ fontSize: 16, color: 'black' }}>{timeAgo(request.stamp_created)}</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ mt: {xs: 0, sm: 4, md: 4, lg: 4, xl: 4, xxl: 4} }}>
                        <Grid container>
                            <Grid xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} sx={{ mt: {xs: 1, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0} }}>
                                <Typography sx={{ fontSize: 14, color: 'gray' }}>Status</Typography>
                                {
                                    current_tab === 'approvals' ?
                                        RequestStatusBadge(request.is_transition_approved) : RequestStatusBadge(request.status)
                                }
                            </Grid>
                            <Grid xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} sx={{ mt: {xs: 2, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0} }}>
                                <Typography sx={{ fontSize: 14, color: 'gray' }}>Progress</Typography>
                                <Box>
                                    <Progress
                                        percent={calculatePercentage(request.status)}
                                        strokeColor={ProgressBarColors(request.status)}
                                        status={ProgressBarStatus(request.status)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mt: 5 }}>
                        {
                            request.type === "WIZARD" ?
                                <Button
                                    size='large'
                                    type='primary'
                                    onClick={() => setOpenDialog(true)}
                                    style={{
                                        backgroundColor: 'black',
                                        fontWeight: 600,
                                        width: '100%'
                                    }}
                                >
                                    View
                                </Button>
                                : <></>
                        }
                    </Box>

                </Card>
        </>
    )
}