import { Tag, notification } from "antd";
import { Typography } from "@mui/material";
import moment from 'moment';
import dayjs from 'dayjs';
import { useOutletContext } from "react-router-dom";

export async function callFlashBackend(
    url,
    method="GET", 
    body=null
) {
const response = await fetch(process.env.REACT_APP_BASE_URL + url, {
    method: method,
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: body,
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken")
    }
});
if (!response.ok) {
    if (response.status === 401) {
        window.location.reload()
        throw new Error("Unauthenticated");
    } else if (response.status === 403) {
        throw new Error("Not Enough Permissions");
    } else if (response.status >= 500) {
        throw new Error(`Failed to call API (${url}) - ${response.status}: ${response.statusText}`);
    } else if (response.status === 429) {
        throw new Error("Too Many Requests, Please wait for some more seconds");
    }
}
const data = await response.json();
return data;
}


export async function flashDataFetcher(url, handleTokenError) {
    if (url) {
        return callFlashBackend(url)
        .then(
            (result) => {
                return result;
            }
        )
        .catch(
            (error) => {
                console.log(`error`, error);
                if (error.message.includes("Unauthenticated") || error.message.includes("Not Enough Permissions")) {
                    handleTokenError(error.message);
                }
                throw error;
            }
        );
    }
}

export function rulesEngineCheck(url, data) {
    // beforeSendCallback();
    return callFlashBackend(url, "POST", JSON.stringify(data))
        .then(
            (result) => {
                if (result.status === 'failure') {
                    throw new Error(result.data)
                } else {
                    return result
                }
            }
        )
        .then(
            (result) => {
                return result
            }
        )
        .catch(
            (error) => {
                console.log(`error`, error);
                return {
                    status: "error" ,
                    message: error.message
                };
            }
        );
}



export function createFlashRequest(data, beforeSendCallback=(() => {}), completeCallback=(() => {})) {
    beforeSendCallback();
    return callFlashBackend("request/STORK", "POST", JSON.stringify(data))
        .then(
            (result) => {
                
                if (result.status === 'failure') {
                    throw new Error(result.data)
                } else {
                    return result;
                }
            }
        )
        .then(
            (result) => {
                completeCallback();
                if (Object.keys(result).includes("UUID")) {
                    return {
                        uuid: result.UUID,
                        status: "success" ,
                        message: "Request created successfully!"
                    };
                } else {
                    console.log(`error`, result);
                    return {
                        status: "error" ,
                        message: result.message
                    };
                }
            }
        )
        .catch(
            (error) => {
                console.log(`error`, error);
                completeCallback();
                return {
                    status: "error" ,
                    message: error.message
                };
            }
        );
}


export function createFlashDataRequest(url, data) {
    // beforeSendCallback();
    return callFlashBackend(url, "POST", JSON.stringify(data))
        .then(
            (result) => {
                
                if (result.status === 'failure') {
                    throw new Error(result.data)
                } else {
                    return {
                        status: "success" ,
                        message: "Successfull!" 
                    }
                }
            }
        )
        .then(
            (result) => {
                return result
            }
        )
        .catch(
            (error) => {
                console.log(`error`, error);
                return {
                    status: "error" ,
                    message: error.message
                };
            }
        );
}



export function RequestStatusBadge(request_state){

    let color = "default"

    if (request_state.toLowerCase() == "draft"){
        color = ""
    }else if (request_state.toLowerCase() == "created"){
        color="processing"
    }else if (request_state.toLowerCase() == "awaiting_approvals" || request_state.toLowerCase() == "awaiting_approval"){
        color="warning"
    }else if (request_state.toLowerCase() == "approved"){
        color="success"
    }else if (request_state.toLowerCase() == "applied"){
        color="success"
    }else if (request_state.toLowerCase() == "completed" || request_state.toLowerCase() == "done"){
        color="success"
    }else if (request_state.toLowerCase() == "rejected" || request_state.toLowerCase() == "request_rejected"){
        color="error"
    }else if (request_state.toLowerCase() == "failed" || request_state.toLowerCase() == "request_failed"){
        color="error"
    }else if (request_state.toLowerCase() == "cancelled" || request_state.toLowerCase() == "request_cancelled"){
        color="error"
    }else{
        color = "default"
    }

    return(
        <Tag color={color} >
            <Typography sx={{fontWeight: 600, fontSize: 12}}>{request_state.toUpperCase()}</Typography>
        </Tag>
    )

}

export function timeAgo(timestamp) {
    // Convert UTC timestamp to IST Kolkata timezone
    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5.5
    const istTimestamp = new Date(timestamp).getTime() + istOffset;
    
    // Get current IST time
    const istCurrentDate = new Date();

    // Calculate time difference in minutes
    const timeDifference = istCurrentDate - istTimestamp;
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    // Format time ago
    if (minutesDifference < 60) {
        return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
    } else {
        const hoursDifference = Math.floor(minutesDifference / 60);
        if (hoursDifference < 24) {
            return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
        } else {
            const daysDifference = Math.floor(hoursDifference / 24);
            return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
        }
    }
}


export function DateTimeFormat(originalDatetimeStr){
    // Assuming your original datetime is in the format "YYYY-MM-DDTHH:mm:ss.SSSZ"
    // Convert the string to a Date object
    var originalDatetime = new Date(originalDatetimeStr);
    // Format the date in the desired formats
    var formattedDate = originalDatetime.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
    var iso8601Format = originalDatetime.toISOString();

    return formattedDate
}


export function calculatePercentage(request_state){
    let percentage = 10;
    if (request_state.toLowerCase() == "draft"){
        percentage = 5;
    }else if (request_state.toLowerCase() == "created"){
        percentage = 10;
    }else if (request_state.toLowerCase() == "awaiting_approvals"){
        percentage = 50;
    }else if (request_state.toLowerCase() == "approved"){
        percentage = 80;
    }else if (request_state.toLowerCase() == "completed"){
        percentage = 100;
    }else if (request_state.toLowerCase() == "done"){
        percentage = 100;
    }else if (request_state.toLowerCase() == "rejected"){
        percentage = 100;
    }else if (request_state.toLowerCase() == "failed"){
        percentage = 100;
    }else if (request_state.toLowerCase() == "cancelled" || request_state.toLowerCase() === "applied"){
        percentage = 100;
    }

    return percentage
}


export const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject('Invalid email address');
};



// eslint-disable-next-line arrow-body-style
export const disabledDate = (current) => {
    // Can not select days before today and today
    return current < dayjs().startOf('day');
  };

export const EndDateValid = (current) => {
  // Can only select days within the next one year and not in the past
  const today = dayjs().startOf('day');
  const oneYearFromNow = today.add(1, 'year');

  return current && (current < today || current > oneYearFromNow);
}


export const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
});


export function getNameFromEmail(email) {
    return email.split("@")[0];
}