import React, { useEffect, useState } from 'react'
import { useOutletContext } from "react-router-dom";
import { Box, Hidden, Typography } from '@mui/material';
import { Menu, Tabs } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined, EllipsisOutlined } from '@ant-design/icons';
import ViewAllRequests from './View/All';
import { flashDataFetcher } from '../../../Utils/flashHelpers';
import AwaitingApproval from './View/Approval';
import { Avatar, Badge } from 'antd';
import MyRequests from './View/MyRequests';

export default function ViewRequests() {
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();
    const [current, setCurrent] = useState('all');

    const [approvalRequestCount, setApprovalRequestCount] = useState('')

    
    async function requiredApprovalRequests() {
        flashDataFetcher(`transitions/assigned/STORK?assigned_to=${loginUser.user_email}&sort_order=desc&status=AWAITING_APPROVAL`).then((data) => {
            if (data.status === "success") {
                setApprovalRequestCount(data.pagination.total)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const items = [
        {
            label: loginUser.scopes.includes('stork.ui_admin') ? 'All Requests' : 'My Requests',
            key: "all",
            icon: <MailOutlined />,
        },
        {
            label: <>Assigned To You {<Badge count={approvalRequestCount} showZero />}</>,
            key: 'approval-needed',
            icon: <AppstoreOutlined />,
        },
    ]

    const onTabChange = (e) => {
        setCurrent(e);
    };


    useEffect(() => {
        handleTabChange('', 'view-requests');
        document.title = "Stork - View Requests";

        requiredApprovalRequests()
    }, [])

    return (
        <>
            <Box sx={{ p: 4 }}>
                <Box>
                    <Typography variant='h4' sx={{ fontWeight: 600 }}>Requests</Typography>
                </Box>
                <Box sx={{ mt: 5 }}>
                    {/* Menu */}
                    <Tabs
                        items={items}
                        style={{ backgroundColor: "transparent", fontWeight: 600 }}
                        onChange={onTabChange}
                        defaultActiveKey="all"
                        // selectedKeys={[current]}
                    />
                    {/* <Menu
                        style={{ backgroundColor: "transparent", fontWeight: 600 }}
                        onClick={onTabChange}
                        selectedKeys={[current]}
                        mode="horizontal"
                        items={items}
                        overflowedIndicator={<EllipsisOutlined />}
                    /> */}
                </Box>

                <Box sx={{ mt: 4 }}>
                    { current === "all" ? loginUser.scopes.includes('stork.ui_admin') ? <ViewAllRequests /> : <MyRequests /> : <></> }
                    { current === "approval-needed" ?  <AwaitingApproval /> : <></> }
                    {/* {} */}
                </Box>
            </Box>

        </>
    )
}
