import React from 'react'
import { Grid, Typography, Box } from '@mui/material'

import Card from 'antd/es/card/Card'
import QuickViewUser from '../../../Components/Global/QuickViewUser'
import { DateTimeFormat } from '../../../../../Utils/flashHelpers'

export default function ExternalAccessExtensionRequestView({ requestData, parameters }) {

    return (
        <>
            <Card
                title={"Access Extension Request"}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Requested By</Typography>
                        <Box sx={{ mt: 1 }}>
                            <QuickViewUser user_id={requestData?.requestor} />
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 4 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>User</Typography>
                            <Box sx={{ mt: 1 }}>
                                <QuickViewUser user_id={parameters?.USER_EMAIL_ADDRESS} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Employee ID</Typography>
                            <Box sx={{ mt: 1 }}>
                                <Typography>{parameters?.USER_EMPLOYEE_ID}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>New Access Expiry Date</Typography>
                            <Box sx={{ mt: 1 }}>
                                {/* <Typography>{DateTimeFormat(parameters?.NEW_END_DATE)}</Typography> */}
                                <Typography>{parameters?.NEW_END_DATE}</Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Business Justification</Typography>
                            <Box sx={{ mt: 1 }}>
                                <Typography>{parameters?.JUSTIFICATION}</Typography>
                            </Box>
                        </Grid>
                    </Grid>

                </Box>

            </Card>

        </>
    )
}
