import React from 'react'
import { useState, useEffect } from 'react';
import { Add } from '@mui/icons-material'
import { Button, Stack, Popover, List, ListItem, Chip, useTheme, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete } from '@mui/material'
import { capitalize } from './helpers';

export default function Filters({ filters, handleFilterUpdate }) {
    const handleRemoveFilter = (filter) => {
        handleFilterUpdate(filter.toLowerCase(), '');
    }
    
    return (
        <>
            <Stack direction={"row"} spacing={2} mb={2} alignItems={"center"} >
                {
                    Object.keys(filters).map(
                        filter => filters[filter].value && 
                                    <Chip key={filter} 
                                        label={
                                            <span>
                                                {`${capitalize(filter)} ${filters[filter].operator.toLowerCase() === 'exact'?'equals':capitalize(filters[filter].operator).toLowerCase()} `}
                                                <strong>{filters[filter].value}</strong>
                                            </span>
                                        }
                                        onDelete={() => handleRemoveFilter(filter)} 
                                        />
                    )
                }
                <AddFilter filters={filters} handleFilterUpdate={handleFilterUpdate} />
                
            </Stack>
        </>
    )
}

export function AddFilter({ filters, handleFilterUpdate }) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterType, setFilterType] = useState(Object.keys(filters).filter(filter => !filters[filter].value)[0]);
    const [searchText, setSearchText] = useState('');
    let open = Boolean(anchorEl);

    useEffect(() => {
        setFilterType(Object.keys(filters).filter(filter => !filters[filter].value)[0] || '');
    }, [open]);
    

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setFilterType('');
        setSearchText('');
        setAnchorEl(null);
    };

    const handleFilterTypeChange = (e, val) => {
        setFilterType(val);
    }

    const handleOperatorChange = (e, val) => {
        handleFilterUpdate(filterType.toLowerCase(), e.target.value, 'operator');
    }

    const handleSearchKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleFilterUpdate(filterType.toLowerCase(), e.target.value, 'value');
            handleClose();
        }
    }

    const handleSearchChange = (e, val) => {
        if (filters[filterType.toLowerCase()].type === 'text') {    
            setSearchText(e.target.value);
        } else if (filters[filterType.toLowerCase()].type === 'select') {
            setSearchText(e.target.value);
            handleFilterUpdate(filterType.toLowerCase(), e.target.value, 'value');
            handleClose();
        } else if (filters[filterType.toLowerCase()].type === 'autocomplete') {
            setSearchText(val);
            handleFilterUpdate(filterType.toLowerCase(), val, 'value');
            handleClose();
        } else if (filters[filterType.toLowerCase()].type === 'free-autocomplete') {
            setSearchText(val);
            handleFilterUpdate(filterType.toLowerCase(), val, 'value');
            handleClose();
        }
    }

    return (
        <>
            <Button color={"inherit"} startIcon={<Add/>} variant={"outlined"} 
                        disabled={Object.keys(filters).filter(filter => !filters[filter].value).length === 0}
                        sx={{ "mx": 2, borderRadius: "10px", "&:hover": {borderColor: theme.palette.primary.main} }} 
                        onClick={handleOpen}>
                Add Filter
            </Button>
            <Popover sx={{ '& .MuiPopover-paper': {borderRadius: '10px', width: 300}}} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} >
                <List>
                    <ListItem>
                        <Autocomplete
                            fullWidth
                            size='small'
                            autoHighlight
                            options={Object.keys(filters).map(filter => !filters[filter].value && capitalize(filter, false)) || []}
                            loading={!Object.keys(filters)}
                            value={capitalize(filterType)}
                            onChange={handleFilterTypeChange}
                            renderInput={(params) => 
                                <TextField fullWidth {...params} label={`Filter`} />
                            } />
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <InputLabel>{`Operator`}</InputLabel>
                            <Select size='small' value={(filterType !== '' && filterType !== null) && filters[filterType.toLowerCase()].operator} label={`Operator`} onChange={handleOperatorChange}>
                                {
                                    (filterType !== '' && filterType !== null) && filters[filterType.toLowerCase()].allowed_operators.map(
                                        option => <MenuItem key={option} value={option}>{option.toLowerCase() === 'exact'?'Equals':capitalize(option)}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </ListItem>
                    {
                        (filterType !== '' && filterType !== null) && filters[filterType.toLowerCase()].type === 'text' &&
                        <ListItem>
                            <TextField fullWidth size='small' value={searchText} label={"Search"} onChange={handleSearchChange} onKeyDown={handleSearchKeyDown} />
                        </ListItem>
                    }
                    {
                        (filterType !== '' && filterType !== null) && filters[filterType.toLowerCase()].type === 'select' &&
                        <ListItem>
                            <FormControl fullWidth>
                                <InputLabel>{capitalize(filterType)}</InputLabel>
                                <Select size='small' value={searchText} label={capitalize(filterType)} onChange={handleSearchChange}>
                                    {
                                        filters[filterType.toLowerCase()].options && filters[filterType.toLowerCase()].options.map(
                                            option => <MenuItem key={option} value={option}>{option}</MenuItem>        
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </ListItem>
                    }
                    {
                        ((filterType !== '' && filterType !== null) && (filters[filterType.toLowerCase()].type === 'autocomplete' || filters[filterType.toLowerCase()].type === 'free-autocomplete')) &&
                        <ListItem>
                                <Autocomplete
                                    size='small'
                                    fullWidth
                                    freeSolo={filters[filterType.toLowerCase()].type === 'free-autocomplete'}
                                    autoHighlight={filters[filterType.toLowerCase()].type !== 'free-autocomplete'}
                                    options={filters[filterType.toLowerCase()].options || []}
                                    loading={!filters[filterType.toLowerCase()].options}
                                    value={searchText}
                                    onChange={handleSearchChange}
                                    renderInput={(params) => 
                                        <TextField fullWidth size='small' {...params} label={capitalize(filterType)} />
                                    } />
                        </ListItem>
                    }
                </List>
            </Popover>
        </>
    );
}