import React, { useEffect, useState } from 'react'
import Card from 'antd/es/card/Card'
import { Typography, Grid, Box } from '@mui/material'
import TextArea from 'antd/es/input/TextArea'
import { Form, Input, Select, DatePicker, Space, Button, Radio, Spin, Alert } from "antd"
import { notification } from "antd"
import { gendersAll, bloodGroups, phonePeDomains, phonePeEntities, accessList, companies } from '../../../../../Utils/constants'
import SearchUser from '../../../Components/Global/SearchUser'
import { useOutletContext } from "react-router-dom";
import { EndDateValid, createFlashRequest, disabledDate, disabledDateTime, flashDataFetcher, validateEmail } from '../../../../../Utils/flashHelpers';
import { DynamicRequestorSelect } from '../../../Components/Global/DynamicRequestor'
import AccessRequestConfirmationDialog from '../../Confirmation/AccessRequestConfirmationDialog'
import QuickViewUser from '../../../Components/Global/QuickViewUser'


export default function ExternalEmployeeExtension() {

  const [form] = Form.useForm();
  const [handleTabChange, handleTokenError, loginUser] = useOutletContext();
  const [triggerSubmission, setTriggerSubmission] = useState(false);
  const [values, setValues] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);


  // Selected User
  const [selectedUser, setSelectedUser] = useState()

  function resetSelectedUser() {
    setSelectedUser(null)
  }


  const CompleteSubmission = (values) => {

    // Append Employee ID and Email Address
    values.USER_EMPLOYEE_ID = selectedUser ? selectedUser.employee_id : null
    values.USER_EMAIL_ADDRESS = selectedUser ? selectedUser.company_email_id : null

    setLoading(true)
    setValues(values)
    setTriggerSubmission(true)
    setLoading(false)
  }

  return (
    <Grid container spacing={2}>
      {contextHolder}
      <Grid item xs={12} sm={10} md={10} lg={10} xl={10} xxl={10}>
        <Card
          title="External Employee Extension"
        >
          {triggerSubmission ? (
            <AccessRequestConfirmationDialog
              triggerSubmission={triggerSubmission}
              setTriggerSubmission={setTriggerSubmission}
              request_title={"External Employee Extension"}
              entity_type={"CREATE"}
              component_name={"ACCESS_EXTENSION_REQUEST"}
              state={"created"}
              parameters={values}
              requestor={loginUser.user_email}
            />
          ) : (
            <></>
          )}
          {
            <SearchEmployeeForExtension
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}

            />
          }
          {
            selectedUser ? <ExternalEmployeeDetailsShow selectedUser={selectedUser} /> : <></>
          }
          {
            selectedUser && selectedUser.employee_type !== "External" ?
              <Box sx={{ mt: 4 }}>
                <Grid>
                  <Alert
                    message={<Typography sx={{ fontWeight: 600 }}>User is not an External Employee!</Typography>}
                    description="Only External Users access requests can be raised from this portal, Please reach out to your HRBP for other accesses extensions. - Extension for Contractors and FTE's can be raised from SuccessFactors Only."
                    type="error"
                    showIcon
                  />
                </Grid>
              </Box> : <></>
          }

          {

            selectedUser && selectedUser.employee_type == "External" ? 

            <Form
            onFinish={CompleteSubmission}
            layout="vertical"
            size='large'
            
          >

            <Box sx={{ mt: 4 }}>
              <AccessExtensionFields />
            </Box>
            <Form.Item>
              <Box sx={{ mt: 4, textAlign: "right" }}>
                <Button
                  disabled={loading}
                  htmlType="submit"
                  style={{ width: 200, height: 40 }}
                  type="primary"
                >
                  {loading ? (
                    <Spin />
                  ) : (
                    <Typography sx={{ fontWeight: 600 }}>
                      Submit & Request
                    </Typography>
                  )}
                </Button>
              </Box>
            </Form.Item>
          </Form> : <></>
          }
        </Card>
      </Grid>
    </Grid>
  )
}


function AccessExtensionFields() {
  return (
    <Grid item sm={12} xs={12} md={12} lg={12}>
      <Box>
        <Form.Item
          name="NEW_END_DATE"
          rules={[
            {
              required: true,
              message: 'End Date cannot be empty!',
            },
          ]}
          label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
            New Access End Date
          </Typography>
          }
        >
          <DatePicker
            format="YYYY-MM-DD"
            disabledDate={EndDateValid}
            disabledTime={disabledDateTime}
            style={{
              width: '100%',
              size: "large"

            }}
          />
        </Form.Item>
      </Box>
      <Box>
        <Form.Item
          name="JUSTIFICATION"
          label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
            Business Justification
          </Typography>}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea
            placeholder="Provide Justification for all mentioned accesses above."
          // autoSize={{
          //     minRows: 4,
          //     maxRows: 6,
          // }}
          />
        </Form.Item>
      </Box>
    </Grid>
  )
}


function ExternalEmployeeDetailsShow({ selectedUser }) {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>Employee Details</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Employee Email</Typography>
          <Box sx={{ mt: 1 }}>
            <QuickViewUser user_id={selectedUser?.company_email_id} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Employee Manager</Typography>
          <Box sx={{ mt: 1 }}>
            <Box sx={{ mt: 1 }}>
              <QuickViewUser user_id={selectedUser?.manager?.company_email_id} />
            </Box>
            {/* <QuickViewUser user_id={requestData?.requested_by} /> */}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Current End Date</Typography>
          <Box sx={{ mt: 1 }}>

            {/*  */}
            <Typography>{selectedUser ? (selectedUser.valid_to === null ? "None" : selectedUser.valid_to) : "NULL"}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Employee Type</Typography>
          <Box sx={{ mt: 1 }}>
            <Typography>{selectedUser ? selectedUser.employee_type : "NULL"}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export function SearchEmployeeForExtension({ selectedUser, setSelectedUser }) {

  const [search, isSearching] = useState(false)
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [api, contextHolder] = notification.useNotification();


  function onFinish(values) {
    setLoading(true)
    flashDataFetcher(`user/exit/${values.SEARCH_EMPLOYEE_EMPLOYEE_ID}`)
      .then((result) => {

        if (result.data === "User Not Found") {

            api.error({
              message: "User Not Found",
              description: `${result.data}`,
            });
            setLoading(false)
            
        } else {

          setSelectedUser(result)
          setLoading(false)
          
        }
      }).catch((error) => {
        api.error({
          message: `Error Happeened - ${error}`,
          description: `${error}`,
        });
        setLoading(false)
      })
  }

  return (
    <Box sx={{ mt: 4 }}>
      {contextHolder}
      <Form
        onFinish={onFinish}
        layout="vertical"
        size='large'
      // initialValues={{
      //   REQUESTOR: loginUser.user_email,
      // }}
      >
        <Form.Item
          name="SEARCH_EMPLOYEE_EMPLOYEE_ID"
          label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
            Enter Employee Email ID
          </Typography>}
          rules={[
            {
              required: true,
              message: 'Employee Email ID cannot be empty!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Box sx={{ textAlign: "right" }}>
            <Button
              disabled={loading}
              htmlType="submit"
              style={{ width: 200, height: 40 }}
              type="primary"
            >
              {loading ? (
                <Spin />
              ) : (
                <Typography sx={{ fontWeight: 600 }}>
                  Search Employee
                </Typography>
              )}
            </Button>
          </Box>
        </Form.Item>

      </Form>
    </Box>
  )
}


