import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import EmployeeQuickView from './Components/EmployeeQuickView'
import { Card, Empty, Spin } from 'antd'
import { Tabs } from 'antd';
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import { useOutletContext } from "react-router-dom";
import QuickLinks from './Components/QuickLinks';
import { ArrowRightOutlined } from '@mui/icons-material';
import { RequestAccessTile } from '../Requests/ComponentsListView';
import { flashDataFetcher } from '../../Utils/flashHelpers';
import { ClockCircleOutlined } from '@ant-design/icons';
import { timeAgo } from '../../Utils/flashHelpers';
import { useNavigate } from 'react-router-dom';
import FormCard from './Components/FormCard';
import AppCategorySelect from './Components/CategoriesSelect';
import AllApplicationsView from './Components/AllApplicationsView';

// V2 Support 


export default function Dashboard() {
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();
    const [selectedApplicationCategory, setSelectedApplicationCategory] = useState('ALL')

    const navigate = useNavigate();

    useEffect(() => {
        handleTabChange('', 'home');
        document.title = "Stork - Home";
    }, [])

    return (
            <Box sx={{ p: 2 }}>
                <Box sx={{ m: 2, }} >
                    <Box>
                        <Typography variant='h5'>Hey, <strong>{loginUser.fullname} 👋</strong></Typography>
                        <Typography variant='p' sx={{ fontSize: 14, fontWeight: 300 }}>Hope, you're staying safe and healthy.</Typography>
                    </Box>

                    <Box sx={{mt:6, mb:6}}>
                        <AppCategorySelect selectedApplicationCategory={selectedApplicationCategory} setSelectedApplicationCategory={setSelectedApplicationCategory}  />
                    </Box>

                    <Box sx={{mt:10}}>
                        {/* <FormCard /> */}
                        { <AllApplicationsView selectedApplicationCategory={selectedApplicationCategory} />}
                        
                    </Box>

                </Box>
            </Box>
    )
}


// function QuickNotificationTile({ request }) {
//     const navigate = useNavigate();

//     return (
//         <Box sx={{ mt: 4 }}>
//             <Card bordered={false} style={{ borderLeft: "4px solid #DFAB31" }}>
//                 <Box sx={{ mb: 2 }}>
//                     <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
//                         <ClockCircleOutlined /> {timeAgo(request.stamp_created ? request.stamp_created : 0)}
//                     </Typography>
//                 </Box>
//                 <Typography sx={{ fontSize: 14 }}>
//                     <strong>{request?.requestor}</strong> is requesting your approval on <strong>{request?.request_title}</strong>
//                 </Typography>
//                 <Box sx={{ mt: 2 }}>
//                     <Typography>
//                         <a
//                             onClick={() => request.type === "WIZARD" ? navigate(`/request/view/${request.unique_id}`, { state: { id: request.unique_id } }) : window.open(`https://jira.phonepe.com/browse/${request.unique_id}`, '_blank', 'noopener,noreferrer')}
//                             href='#'>
//                             View Request</a></Typography>
//                 </Box>
//             </Card>
//         </Box>

//     )
// }