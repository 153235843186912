import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

// import v2 fallback to v1
import SideBar from "../V2/Navigation/SideBar";
import TopBar from "../V2/Navigation/TopBar";

// import SideBar from "../Components/Naviagtion/SideBar";

import PerfectScrollbar from 'react-perfect-scrollbar';

export default function MainLayout({ loginUser, handleModeChange, handleLogout, handleTokenError }) {
    
    const [currentTab, setCurrentTab] = useState("home");
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    function handleTabChange(e, value) {
        setCurrentTab(value);
    }

    return (
        <Box sx={{ display: 'flex', width: '100vw',
            backgroundColor:'#F7F9FD'
        }} >
            <SideBar
                title={currentTab}
                loginUser={loginUser}
                handleModeChange={handleModeChange}
                handleLogout={handleLogout} 
                open={open}
                setOpen={setOpen}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                
                />
            <Box component="main"
                sx={{
                     width: '100%', overflow: 'auto',
                    pt: 0, 
                    // zIndex: theme.zIndex.drawer + 1
                }}>
                {/* <PerfectScrollbar> */}
                    <Box sx={{
                        borderRadius: '10px', py: 0, m: 0,
                        backgroundColor: theme.palette.background.container,
                        // backgroundColor: '#F7F9FD',
                        minHeight: '100vh',
                        overflow: 'auto'
                    }}>
                        {/* Declare Top Bar */}
                        <TopBar
                                  title={currentTab}
                                  loginUser={loginUser}
                                  handleModeChange={handleModeChange}
                                  handleLogout={handleLogout}
                                  setOpen={setOpen}
                                  open={open}
                                  handleDrawerOpen={handleDrawerOpen}
                                  handleDrawerClose={handleDrawerClose}
                        />
                        <Outlet context={[handleTabChange, handleTokenError, loginUser]} />
                    </Box>
            </Box>
        </Box>
    )
}