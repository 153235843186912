import React from 'react'
import { Breadcrumb, Card } from 'antd';
import { Box } from '@mui/material';

export default function BreadCrumb({links}) {
  return (
        <Breadcrumb
        separator="/"
        items={links}
        style={{fontSize: 14, fontWeight: 500}}
    />

  )
}
