import React from 'react';
import { Container, Typography, useTheme, Alert, AlertTitle } from "@mui/material";

export default function ServerError({ message }) {
	const theme = useTheme();
	document.title = "STORK - Internal Server Error";
	
	return (
		<Container sx={{textAlign: 'center', height: '100vh', display: 'flex', borderColor: theme.palette.background.default }} >
			<Container>
				<img src="/500_ice_cream.png" alt="500 | Internal Server Error" style={{height: '50vh'}} />
				<Typography variant='h3' sx={{my: 2}} >Something went wrong</Typography>
				{
					message &&
					<Container maxWidth='sm'>
						<Alert severity="error" sx={{my: 2}}>
							<AlertTitle sx={{textAlign: 'left'}} >Error</AlertTitle>
							{message}
						</Alert>
					</Container>
				}
			</Container>
		</Container>
	)
}
