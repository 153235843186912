import { Button, Drawer, Space, notification } from 'antd'
import React from 'react'
import ViewRequest from '../../View/RequestView'
import { Box, Typography } from '@mui/material'

export default function FullScreenViewRequestView({setOpenDialog, openDialog, request_uuid}) { 

  return (
    <>
    <Drawer
        title={`View Request`}
        placement={"bottom"}
        style={{backgroundColor:'#f2f4f7'}}
        height={'95%'}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
        extra={
          <Space>
            <Button onClick={() => setOpenDialog(false)} type="primary" danger>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>X</Typography>
            </Button>
          </Space>
        }
      >
        <Box>
            <ViewRequest request_uuid={request_uuid} />
        </Box>
      </Drawer>
    </>
  )
}
