import React, { useState } from 'react';
import {
    Tooltip, Typography, Button, Avatar, Card,
    CardContent, Box, Divider, Popover, IconButton, styled, Paper
} from "@mui/material";
import { Brightness7, Brightness4, Logout } from "@mui/icons-material";
import { ShadowedButton } from '../../../StyledComponents';
import { useWindowSize } from "../../../Utils/useWindowSize";

export default function GsuiteProfile({ loginUser, handleModeChange, handleLogout, theme }) {
    return (
        <>
            <Box sx={{p:0}}>
                <h5>{loginUser.user_email}</h5>
            </Box>
            
        </>
    )
}