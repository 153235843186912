import React from 'react'
import { FormTiles } from '../../Requests/ComponentsListView/componentConstants'
import { Box, Grid, Typography } from '@mui/material'
import FormCard from './FormCard'
import { Flex } from 'antd'
{/* <FormCard /> */ }


export default function AllApplicationsView({selectedApplicationCategory}) {


  let applicationArray = selectedApplicationCategory === "ALL" ? FormTiles : FormTiles.filter((tile => tile.block_category_code === selectedApplicationCategory))

  return (
    <>
      {
        applicationArray.map((tile) => {
          return (
            <Box sx={{ mb: 8 }}>
              <Box sx={{ mb: 1 }}>
                <Typography variant='h6' sx={{ fontWeight: 600, fontSize: 18 }}>{tile?.block_title}</Typography>
              </Box>
              <Box sx={{mt:4}}>
                <Grid container spacing={3} >
                  {
                    tile.tiles.map((access, index) => {
                      return (
                        <>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3} xxl={3}>
                              <FormCard access={access} index={index} />
                          </Grid>                
                        </>
                      )
                    })
                  }
                </Grid>
              </Box>
            </Box>
          )
        })
      }
    </>
  )
}
