// List of genders for all forms
export const gendersAll = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Prefer not to mention', label: 'Prefer not to mention' }
]

// List of Blood Groups
// Users are required to put Blood groups.

export const bloodGroups = [
    { value: 'A+', label: 'A+' },
    { value: 'A-', label: 'A-' },
    { value: 'B+', label: 'B+' },
    { value: 'B-', label: 'B-' },
    { value: 'O-', label: 'O-' },
    { value: 'O+', label: 'O+' },
    { value: 'AB+', label: 'AB+' },
    { value: 'AB-', label: 'AB-' },
]

// List of PhonePe Entities

export const phonePeEntities = [
    { value: 'PhonePe Private Limited', label: 'PhonePe Private Limited' },
    { value: 'PhonePe Technology Private Limited', label: 'PhonePe Technology Private Limited' },
    { value: 'Phonepe Wealth Broking Private Limited', label: 'Phonepe Wealth Broking Private Limited' },
    { value: 'Phonepe Insurance Broking Services Private Limited' , label: 'Phonepe Insurance Broking Services Private Limited' },
    { value: 'Pincode Shopping Solutions Private Limited' , label: 'Pincode Shopping Solutions Private Limited' },
    { value: 'PhonePe Credit Services Private Limited' , label: 'PhonePe Credit Services Private Limited' },
    { value: 'Wealth Technology and Services Private Limited' , label: 'Wealth Technology and Services Private Limited' },
    { value: 'Quantech Capital Investment Advisors Private Limited' , label: 'Quantech Capital Investment Advisors Private Limited' },
    { value: 'Phonepe Private Limited, US Branch' , label: 'Phonepe Private Limited, US Branch' },
    { value: 'Oslabs Technology (India) Private Limited' , label: 'Oslabs Technology (India) Private Limited' },
]

// List of required domains
export const phonePeDomains = [
    { value: 'phonepe.in', label: 'phonepe.in' },
    { value: 'phonepe.com', label: 'phonepe.com' },
    // { value: 'phonepecs.com', label:  'phonepecs.com'}
]

// True or false

export const trueAndFalseMap = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
]

export const InstantMessagingList = [
    { value: 'WHATSAPP', label: 'WhatsApp' },
    { value: 'SKYPE', label: 'Skype' },
    { value: 'TELEGRAM', label: 'Telegram' },
    { value: 'SIGNAL', label: 'Signal' }
]


export const accessList = [
    { value: "VDI", label: "VDI"},
    { value: "PHONEPE_EMAIL", label: "PhonePe Email Address"},
    { value: "LAPTOP", label: "Laptop"},
    { value: "VM", label: "Virtual Machine"},
    { value: "VPN", label: "VPN Access"},
    { value: "SSH", label: "SSH Access to DC Servers"},
    { value: "JIRA_CONFLUENCE", label: "Jira and Confluence"},
    { value: "INTERNET_ACCESS", label: "Internet Access"},
    { value: "SHARE_DRIVE", label: "Share Drive Access"},
    { value: "MEETINGS_AND_CALENDAR", label: "Meetings and Calendar"},
    { value: "LICENSE_FOR_COMMERCIAL_TOOLS", label: "License for Commercial Tools"},
    { value: "SUCCESS_FACTORS", label: "Success Factors"},
]

export const companies = [
    { value: "Wise Travel India Private Limited", label: "Wise Travel India Private Limited" },
    { value: "Carz on Rent India Pvt Ltd", label: "Carz on Rent India Pvt Ltd" },
    { value: "ECOS (I) MOBILITY & HOSPITALITY PVT. LTD", label: "ECOS (I) MOBILITY & HOSPITALITY PVT. LTD" },
    { value: "Ascent HR", label: "Ascent HR" },
    { value: "Make mytrip India Pvt Ltd", label: "Make mytrip India Pvt Ltd" },
    { value: "SPR & Co", label: "SPR & Co" },
    { value: "Tenon Facility Management", label: "Tenon Facility Management" },
    { value: "SIS Limited", label: "SIS Limited" },
    { value: "Elior India Food Services LLP", label: "Elior India Food Services LLP" },
    { value: "Bottle Lab Technologies Pvt Ltd", label: "Bottle Lab Technologies Pvt Ltd" },
    { value: "Subek Agrawal", label: "Subek Agrawal" },
    { value: "Deloitte Touche Tohmatsu India LLP", label: "Deloitte Touche Tohmatsu India LLP" },
    { value: "Protiviti india Member Private Limited", label: "Protiviti india Member Private Limited" },
    { value: "Les Concierges service pvt Ltd", label: "Les Concierges service pvt Ltd" },
    { value: "Authbridge Research Service Pvt Ltd", label: "Authbridge Research Service Pvt Ltd" },
    { value: "TeamParkme Parking Solutions Pvt Ltd", label: "TeamParkme Parking Solutions Pvt Ltd" },
    { value: "MattsenKumar Services Private Limited", label: "MattsenKumar Services Private Limited" },
    { value: "Secure Parking Solutions Pvt Ltd", label: "Secure Parking Solutions Pvt Ltd" },
    { value: "Founding Years Learning Solutions Pvt Ltd", label: "Founding Years Learning Solutions Pvt Ltd" },
    { value: "Smarbytes", label: "Smarbytes" },
    { value: "Qualtrix", label: "Qualtrix" },
    { value: "Vtest", label: "Vtest" },
    { value: "Tessolve/ Test & Verification", label: "Tessolve/ Test & Verification" },
    { value: "Moolya", label: "Moolya" },
    { value: "Cognitive Cloud", label: "Cognitive Cloud" },
    { value: "Zenith", label: "Zenith" },
    { value: "Shorewise", label: "Shorewise" },
    { value: "Innoventes", label: "Innoventes" },
    { value: "Aricent/Capgemini", label: "Aricent/Capgemini" },
    { value: "Warp Drive", label: "Warp Drive" },
    { value: "Jash Data Science", label: "Jash Data Science" },
    { value: "Dreams and Events", label: "Dreams and Events" },
    { value: "US Microsemi", label: "US Microsemi" },
    { value: "Innovsource", label: "Innovsource" },
    { value: "Futurz", label: "Futurz" },
    { value: "Quess Corp", label: "Quess Corp" },
    { value: "Netambit", label: "Netambit" },
    { value: "Careernet", label: "Careernet" },
    { value: "Aegis", label: "Aegis" },
    { value: "Hexaware", label: "Hexaware" },
    { value: "Concentrix", label: "Concentrix" },
    { value: "Tech Mahindra", label: "Tech Mahindra" },
    { value: "Grant Thorton Bharat", label: "Grant Thorton Bharat" },
    { value: "KPMG", label: "KPMG" },
    { value: "Payatu Security Consulting", label: "Payatu Security Consulting" },
    { value: "Deloitte", label: "Deloitte" },
    { value: "PwC", label: "PwC" },
    { value: "Ecom Express", label: "Ecom"}
]

export const USBaccessList = [
    { value: "TRANSFER_DATA", label: "Transfer Data"},
    { value: "DCS_ACCESS", label: "Access for DSC (Digital Signature Creation)"},
    { value: "CONNECT_FOR_PERFORM_TESTING", label: "Connecting a device to perform testing for any application (Andoid / iOS)"},
    { value: "CONNECT_WITH_EXTERNAL_HARDWARE", label: "Connecting with an external hardware for any project requirement"},
    { value: "OTHER", label: "Other"},

]
