import React from 'react'
import { Card , Descriptions, Divider } from 'antd'
import { Typography , Grid, Box} from '@mui/material'
import QuickViewUser from '../../../Components/Global/QuickViewUser'
import { DateTimeFormat } from '../../../../../Utils/flashHelpers'

export default function ViewAuditorRequestForm({requestData, parameters}) {
  return (
    <Card 
    // title={"Request Details"}
    >
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: 16, fontWeight: 600}}>Requestor</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Requested By</Typography>
                <Box sx={{mt:1}}>
                    <QuickViewUser user_id={requestData?.requestor} />
                </Box>
            </Grid>
        </Grid>
        <Divider/>
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: 16, fontWeight: 600}}>Basic Details</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3} xxl={3}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>First Name</Typography>
                <Box sx={{mt:1}}>
                    <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.FIRST_NAME : 'N/A'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3} xxl={3}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Last Name</Typography>
                <Box sx={{mt:1}}>
                    <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.LAST_NAME : 'N/A'}
                    </Typography>                
                </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3} xxl={3}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Full Name</Typography>
                <Box sx={{mt:1}}>
                    <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.FULL_NAME : 'N/A'}
                    </Typography> 
                </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3} xxl={3}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Gender</Typography>
                <Box sx={{mt:1}}>
                <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.GENDER : 'N/A'}
                    </Typography> 
                </Box>
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3} xxl={3}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Blood Group</Typography>
                <Box sx={{mt:1}}>
                    <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.BLOOD_GROUP : 'N/A'}
                    </Typography> 
                </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3} xxl={3}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Starting Date</Typography>
                <Box sx={{mt:1}}>
                    <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        { DateTimeFormat(parameters.START_DATE)}
                    </Typography> 
                </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3} xxl={3}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Ending Date</Typography>
                <Box sx={{mt:1}}>
                    <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        { DateTimeFormat(parameters.END_DATE)}
                    </Typography> 
                </Box>
            </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={6} sx={{mt:1}}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: 16, fontWeight: 600}}>PhonePe Association Details</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>PhonePe Entity</Typography>
                <Box sx={{mt:1}}>
                    <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.PHONEPE_ENTITY : 'N/A'}
                    </Typography> 
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>PhonePe Manager</Typography>
                <Box sx={{mt:1}}>
                <Typography sx={{fontSize: 14, fontWeight: 400}}>
                    <QuickViewUser 
                    user_id={parameters ? parameters.PHONEPE_MANAGER : 'N/A'} 
                    />
                    </Typography> 
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Domain Required</Typography>
                <Box sx={{mt:1}}>
                    <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.EMAIL_DOMAIN : 'N/A'}
                    </Typography> 
                </Box>
            </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={4} sx={{mt:1}}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: 16, fontWeight: 600}}>Agency Details</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Agency Name</Typography>
                <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.AGENCY_NAME : 'N/A'}
                    </Typography> 
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Agency Email Address</Typography>
                <Box sx={{mt:1}}>
                    <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.AGENCY_EMAIL_ADDRESS : 'N/A'}
                    </Typography> 
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Agency Phone Number</Typography>
                <Box sx={{mt:1}}>
                <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.AGENCY_PHONE_NUMBER : 'N/A'}
                </Typography> 
                </Box>
            </Grid>
        </Grid>
        <Divider/>
        <Grid container spacing={4} sx={{mt:1}}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: 16, fontWeight: 600}}>Audit Environment Details</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Scope of Work</Typography>
                <Box sx={{mt:1}}>
                <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.SCOPE_OF_WORK : 'N/A'}
                </Typography> 
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Audit Coverage</Typography>
                <Box sx={{mt:1}}>
                <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.AUDIT_COVERAGE : 'N/A'}
                </Typography> 
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Environment IP Ranges</Typography>
                <Box sx={{mt:1}}>
                <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? parameters.ENVIRONMENT_IP_RANGES : 'N/A'}
                </Typography> 
                </Box>
            </Grid>
           
        </Grid>
        <Divider/>
        <Grid container spacing={4} sx={{mt:1}}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: 16, fontWeight: 600}}>Accesses Needed</Typography>
                <Box sx={{mt:1}}>
                <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? 
                        parameters.LIST_OF_ACCESSES_NEEDED.map((access) => {
                            return <Typography>{access}</Typography>
                        }) 
                        
                        : 'N/A'}
                </Typography> 
                </Box>
            </Grid>
        </Grid>
        <Divider/>

        <Grid container spacing={4} sx={{mt:1}}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: 16, fontWeight: 600}}>Justification</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>Business Justification</Typography>
                <Box sx={{mt:1}}>
                    <Typography sx={{fontSize: 14, fontWeight: 400}}>
                    {parameters ? parameters.JUSTIFICATION : 'N/A'}
                    </Typography>
                </Box>
            </Grid>
        </Grid>

        {/* <Descriptions layout="vertical" items={ListItems} /> */}
    </Card>
  )
}
