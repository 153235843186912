import React from 'react'
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Result, Typography,Card } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Paragraph, Text } = Typography;


export default function ResultArea({status, title, subTitle, lightButtonLink, 
    darkButtonLink, lightButtonText, darkButtonText, pointsToShare}) {

    const navigate = useNavigate();
    return (
        <Card>
        <Result
            status={status && status ? status : 'error'}
            title={title && title ? title : 'ah! this looks really bad'}
            subTitle={subTitle && subTitle ? subTitle : 'this error is not expected at all'}
            extra={[
               
                <Button 
                onClick={() =>  navigate(darkButtonLink)}
                type="primary" key="console">
                    {darkButtonText}
                </Button>,

            ]}
        >
            <div className="desc">
                <Paragraph>
                    <Text
                        strong
                        style={{
                            fontSize: 16,
                        }}
                    >
                        What Happens Next?
                    </Text>
                </Paragraph>
                {
                    pointsToShare ? pointsToShare.map((point, index) => {
                        return ( <Paragraph key={index}>{point} </Paragraph> )
                    }) : <></>
                }

            </div>
        </Result>
        </Card>
    )
}
