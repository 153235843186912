import React from 'react';
import { Link } from "react-router-dom";
import { Grid, Box, Typography, useTheme } from "@mui/material";

export default function NotFound() {
	const theme = useTheme();
	document.title = "UMS - NOT FOUND";
	return (
		<Box sx={{textAlign: 'center', height: '100vh', display: 'flex', backgroundColor: theme.palette.secondary.main }} >
			<Grid container>
				<Grid item xs={12} mt={6} alignSelf='center' >
					<img src="/404_ghost.png" alt="404 | Not Found" style={{height: '50vh'}} />
				</Grid>
				<Grid item xs={12} mx={{ xs: 3, md: 10, lg: 30, xl: 50 }} >
					<Typography variant='h3' sx={{my: 2}} >This Page is a Ghost</Typography>
					<Typography variant='body2'>Once alive and now dead, this ghost appears to have some unfinished business. Could it be with you? Or the treasure hidden under the floorboards of the old mansion in the hills that may never reach its rightful owner, a compassionate school teacher in Brooklyn.</Typography>
					<Typography variant='caption' color={theme.palette.text.link} component={Link} to='/' >Anyway, Click here to go back to home</Typography>
				</Grid>
			</Grid>
		</Box>
	)
}
