import React from 'react'
import { Box, Typography } from '@mui/material'
import { Form, useParams } from 'react-router-dom';
import BreadCrumb from '../Components/BreadCrumb';
import {notification} from "antd"
import SampleRequestForm from '../WizardFlow/Forms/DemoComponents/SampleRequestForm';
import CreateAuditorRequest from '../WizardFlow/Forms/AuditorRequestForm/AuditorRequestCreate';
import CreateExternalUserRequest from '../WizardFlow/Forms/ExternalRequestForm/ExternalRequestCreate';
import ExternalEmployeeExtension from '../WizardFlow/Forms/ExternalEmployeeExtension/ExternalEmployeeExtension';
import CreateInstantMessagingRequest from '../WizardFlow/Forms/InstantMessaging/InstantMessagingCreate';
import CreateUSBAccessRequest from '../WizardFlow/Forms/USBAccessRequestForm/USBAccessRequestCreate';
import CreateSoftwareInstallRequest from '../WizardFlow/Forms/SoftwareInstallForm/SoftwareInstallRequestCreate'
import CreateMailTransferForm from '../WizardFlow/Forms/MailTransferForm/MailTransferCreate'
import CreateShareDocExternalRequest from '../WizardFlow/Forms/ShareDocExternalForm/ShareDocExternalRequest'
import CreateLaptopAdminAccessRequest from '../WizardFlow/Forms/LaptopAdminAccessForm/LaptopAdminAccessRequest'

// Form Routes

const FormRoutes = {
    "auditor-create": {
        "title": "Create Auditor Account",
        "component": <CreateAuditorRequest />,
        "pageTitle": "Requesting to Create Auditor Account"
    },
    "external-onboarding": {
      "title": "Create external account",
      "component": <CreateExternalUserRequest />,
      "pageTitle": "Requesting to Create External Account"
    },
    "access-extension": {
      "title": "Request for Access Extension",
      "component": <ExternalEmployeeExtension />,
      "pageTitle": "Request for Access Extension "
    },
    "instant-messaging": {
      "title": "Create Instant Messaging Request",
      "component": <CreateInstantMessagingRequest />,
      "pageTitle": "Request for Instant Messaging"
    },

     "usb-access": {
      "title": "Create USB Access Request",
      "component": < CreateUSBAccessRequest/>,
      "pageTitle": "Request for USB Access"
    },
     "software-install": {
      "title": "Create Software/Plugins Installation Request",
      "component": < CreateSoftwareInstallRequest/>,
      "pageTitle": "Request For Software/Plugins Install"
    },
    "mail-transfer": {
      "title": "Request for Mail Transfer",
      "component": < CreateMailTransferForm/>,
      "pageTitle": "Request for Mail Transfer"
    },
     "share-doc": {
      "title": "Share Document Externally Request Details",
      "component": < CreateShareDocExternalRequest/>,
      "pageTitle": "Request for Share Document Externally"
    },
      "admin-access": {
      "title": "Admin Access for Laptop",
      "component": < CreateLaptopAdminAccessRequest/>,
      "pageTitle": "Request for Admin permissions"
    }


}

export default function FormRouter() {
  const { form } = useParams();
  const formTitle = (FormRoutes[form] && FormRoutes[form].title) ? FormRoutes[form].title : "" 
  const pageTitle = (FormRoutes[form] && FormRoutes[form].pageTitle) ? FormRoutes[form].pageTitle : "" 
  const [api, contextHolder] = notification.useNotification();


  return (
    <>
        <Box sx={{p:4}}>
        {contextHolder}
        {
          <Box sx={{ mt: 2,  }}>
            {
                <BreadCrumb 
                    links={[
                    {
                      "title": "Home",
                      "href": "/"
                    },
                    // {
                    //   "title": "Raise a request",
                    //   "href": "/create-request"
                    // },
                    {
                      "title": `${formTitle}`,
                      "href": ""
                    },
                    ]}
                />
            }
            {/* {
              <BreadCrumb
            
              />
            } */}
          </Box>
        }
        <Box sx={{ mt: 3, mb: 5 }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>{pageTitle}</Typography>
        </Box>
          <Box>
              {(FormRoutes[form] && FormRoutes[form].component) ? FormRoutes[form].component : "" }
          </Box>
        </Box>
    </>
  )
}
