import React, { useState } from "react"
import { Paper, Typography } from '@mui/material'
import { AutoComplete } from 'antd'
import { Form } from "antd";
import { flashDataFetcher } from "../../../../Utils/flashHelpers";
import { validateEmail } from "../../../../Utils/flashHelpers";
import { useOutletContext } from "react-router-dom";

export default function SearchUser({
    selectedOption, setSelectedOption, FieldName, FieldLabel
  }){

    const [options, setOptions] = useState([])

    const handleSearch = async (value) => {
        if (value) {
          if (value.length > 4) {
            flashDataFetcher(`user?company_email_id=${value}`).then((data) => {
              createOptions(data)
            }).catch((error) => {
              console.log(`Error - ${error}`)
            })
          }
        }
      };
    
      const createOptions = (options) => {
        let emails = []
        options?.map((user) => {
          emails.push({
            label: (
              <Paper sx={{p:1}} variant="outlined">
                <Typography sx={{ fontSize: 12 , fontWeight: 500}}>{user.company_email_id}</Typography>
                <Typography sx={{ fontSize: 10 }}>{user.designation}</Typography>
              </Paper>
            ),
            value: user.company_email_id
          })
        })
        setOptions(emails)
      }

      const onSelect = (data) => {
        setSelectedOption(data)
      };


    return(
      <Form.Item
      name={FieldName}
      label={<Typography sx={{ fontSize: 12, fontWeight: 500 }}>
        {FieldLabel}
      </Typography>}
      rules={[
        {
          required: true,
          message: `${FieldLabel} cannot be empty!`,
        },
      {
          required: 'email',
          message: 'The input is not valid E-mail!',
      },
      {
          min: 5,
          message: "'Email' must be between 5 and 255 characters.",
      },
      {
          validator: validateEmail,
      },
      ]}
    >
        <AutoComplete
        options={options}
        style={{
          width: '100%',
        }}
        // defaultValue={}
        onSearch={handleSearch}
        // onSelect={onSelect}
        placeholder={"Enter User Email Address"}
      />
      </Form.Item>
    )
}

// Make sure to pass the parameters to get the details
export function SearchExternalUser({
  selectedOption, setSelectedOption, FieldName, FieldLabel
}){

  const [options, setOptions] = useState([])

  const handleSearch = async (value) => {
      if (value) {
        if (value.length > 4) {
          flashDataFetcher(`user?company_email_id=${value}`).then((data) => {
            createOptions(data)
          })
        }
      }
    };
  
    const createOptions = (options) => {
      let emails = []
      options?.map((user) => {
        emails.push({
          label: (
            <Typography sx={{ fontSize: 14 }}>{user.company_email_id}</Typography>
          ),
          value: user.company_email_id
        })
      })
      setOptions(emails)
    }

    const onSelect = (data) => {
      setSelectedOption(data)
    };


  return(
    <Form.Item
    name={FieldName}
    label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
      {FieldLabel}
    </Typography>}
    rules={[
      {
        required: true,
        message: `${FieldLabel} Entity cannot be empty!`,
      },
    ]}
  >
      <AutoComplete
      options={options}
      style={{
        width: '100%',
      }}
      value={loginUser.user_email}
      onSearch={handleSearch}
      // onSelect={onSelect}
      placeholder={"Enter User Email Address"}
    />
    </Form.Item>
  )
}