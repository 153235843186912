import React, { useEffect, useState } from 'react'
import Card from 'antd/es/card/Card'
import { Typography, Grid, Box } from '@mui/material'
import TextArea from 'antd/es/input/TextArea'
import { Form, Input, Select, DatePicker, Space, Button, Radio, Checkbox } from "antd"
import { notification } from "antd"
import { InstantMessagingList } from '../../../../../Utils/constants'
import SearchUser from '../../../Components/Global/SearchUser'
import { useOutletContext } from "react-router-dom";
import { EndDateValid, createFlashRequest, disabledDate, disabledDateTime, validateEmail } from '../../../../../Utils/flashHelpers';
import { DynamicRequestorSelect } from '../../../Components/Global/DynamicRequestor'
import AccessRequestConfirmationDialog from '../../Confirmation/AccessRequestConfirmationDialog'


export default function CreateInstantMessagingRequest() {
    const [form] = Form.useForm();
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();
    const [triggerSubmission, setTriggerSubmission] = useState(false);
    const [values, setValues] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setValues(values);
        setTriggerSubmission(true);
        setLoading(false);
    }

    return (
        <Grid container spacing={2}>
            {contextHolder}
            <Grid item xs={12} sm={10} md={10} lg={10} xl={10} xxl={10}>
                {triggerSubmission ? (
                    <AccessRequestConfirmationDialog
                        triggerSubmission={triggerSubmission}
                        setTriggerSubmission={setTriggerSubmission}
                        request_title={"Instant Messaging Request"}
                        entity_type={"CREATE"}
                        component_name={"INSTANT_MESSAGING_FORM"}
                        state={"created"}
                        parameters={values}
                        requestor={values ? values.DYNAMIC_REQUESTOR : null}
                    />
                ) : (
                    <></>
                )}
                <Form
                    onFinish={onFinish}
                    layout="vertical"
                    initialValues={{
                        DYNAMIC_REQUESTOR: loginUser.user_email,
                    }}
                >
                    {
                        <DynamicRequestorSelect />
                    }
                    {

                    }
                    {
                        <AccessDetailsSubmission />
                    }
                    <Form.Item>
                        <Box sx={{ mt: 4, textAlign: "right" }}>
                            <Button
                                disabled={loading}
                                htmlType="submit"
                                style={{ width: 200, height: 40 }}
                                type="primary"
                            >
                                {loading ? (
                                    <Spin />
                                ) : (
                                    <Typography sx={{ fontWeight: 600 }}>
                                        Submit & Request
                                    </Typography>
                                )}
                            </Button>
                        </Box>
                    </Form.Item>
                </Form>
                {/* </Card> */}
            </Grid>
        </Grid>
    )
}

function AccessDetailsSubmission() {
    return (
        <>
            <Card
                style={{ marginTop: 20 }}
                title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                    Details
                </Typography>}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        <Box>
                            <Box>
                                <Form.Item
                                    name="START_DATE"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Start Date cannot be empty!',
                                        },
                                    ]}
                                    label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                        Start Date
                                    </Typography>
                                    }
                                >
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        disabledDate={disabledDate}
                                        disabledTime={disabledDateTime}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        <Box>
                            <Box>
                            <Form.Item
                            name="END_DATE"
                            rules={[
                                {
                                    required: true,
                                    message: 'End Date cannot be empty!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject('End Date is required!');
                                        }
                                        const startDate = getFieldValue('START_DATE');
                                        if (!startDate) {
                                            return Promise.reject('Please select a Start Date first!');
                                        }

                                        const maxEndDate = startDate.clone().add(1, 'year');
                                        if (value.isAfter(maxEndDate)) {
                                            return Promise.reject('End Date must be within one year of the Start Date!');
                                        }

                                        if (value.isBefore(startDate)) {
                                            return Promise.reject('End Date cannot be before the Start Date!');
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                End Date
                            </Typography>}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                disabledDate={disabledDate}
                                disabledTime={disabledDateTime}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={12}>
                        <Box>
                            <Form.Item
                                name="INSTANT_MESSAGING_TYPE"
                                label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                    Select the Instant Messaging
                                </Typography>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select the Instant Messaging!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Instant Messaging"
                                    style={{
                                        width: '100%',
                                    }}
                                    options={InstantMessagingList}
                                >

                                </Select>
                            </Form.Item>
                        </Box>
                    </Grid>

                    <Grid item sm={12} xs={12} md={12} lg={12}>
                        <Box sx={{ mb: 2 }}>
                            <Form.Item
                                label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                    Provide Confirmation Regarding PII / Sensitive Data
                                </Typography>}
                                name="PII_CONFIRMATION"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    // placeholder="Select a option and change input text above"
                                    // onChange={onGenderChange}
                                    allowClear
                                >
                                    <Option value="PII_YES"><strong>Yes,</strong> I will be sharing PII / Sensitive Data over Instant Messaging</Option>
                                    <Option value="PII_NO"><strong>No,</strong> I will not be sharing any PII/ Sensitive Data Over Instant Messaging</Option>
                                </Select>
                            </Form.Item>
                        </Box>

                        <Box>
                            <Form.Item
                                name="JUSTIFICATION"
                                label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                    Business Justification
                                </Typography>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Business Justification cannot be empty!',
                                    },
                                ]}
                            >
                                <TextArea
                                    placeholder="Provide Justification for all mentioned accesses above."
                                    autoSize={{
                                        minRows: 4,
                                        maxRows: 6,
                                    }}
                                />
                            </Form.Item>
                        </Box>
                    </Grid>
                </Grid>

            </Card>
        </>
    )
}

function BusinessJustification() {
    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                Justification
            </Typography>}
        >
        </Card>
    )
}
