import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
    palette: {
        mode: "light",
        common: { black: "#000", white: "#fff" },
        background: {
            paper: "rgba(255, 255, 255, 1)",
            default: "#171923",
            container: "#f2f4f7",
            background: '#ffff'
        },
        primary: {
            light: "rgba(27, 30, 70, 1)",
            main: "#0064F9",
            dark: "rgba(45, 9, 95, 1)",
            contrastText: "#fff"
        },
        secondary: {
            light: "rgba(27, 35, 63, 1)",
            main: "rgba(242, 157, 181, 1)",
            dark: "rgba(204, 141, 186, 1)",
            contrastText: "#fff"
        },
        text: {
            light: "#fff",
        }
    },
    typography: {
        menuCaption: {
            fontSize: '0.875rem',
            fontWeight: 600,
            padding: '6px',
            textTransform: 'capitalize',
            marginTop: '10px',
            color: '#fff'
        },
        fontFamily: 'poppins'
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundColor: "#9DB2BF !important",
                },
                root: {
                    textAlign: 'center !important'
                }
            }
        }
    }
});

export const darkTheme = createTheme({
    palette: {
        mode: "light",
        common: { black: "#000", white: "#fff" },
        background: {
            paper: "rgba(255, 255, 255, 1)",
            default: "#171923",
            container: "#F3F7F0",
            background: '#ffff'
        },
        primary: {
            light: "rgba(27, 30, 70, 1)",
            main: "#0064F9",
            dark: "rgba(45, 9, 95, 1)",
            contrastText: "#fff"
        },
        secondary: {
            light: "rgba(27, 35, 63, 1)",
            main: "rgba(242, 157, 181, 1)",
            dark: "rgba(204, 141, 186, 1)",
            contrastText: "#fff"
        },
        text: {
            light: "#fff",
        }
    },
    typography: {
        menuCaption: {
            fontSize: '0.875rem',
            // fontWeight: 700,
            padding: '6px',
            textTransform: 'capitalize',
            marginTop: '10px',
            color: '#fff'
        },
        fontFamily: 'poppins'
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundColor: "#9DB2BF !important",
                },
                root: {
                    textAlign: 'center !important'
                }
            }
        }
    }
});
// F2EBFD
export function agGridTheme(mode) {
    document.documentElement.style.setProperty('--ag-background-color', "#ffff");
    document.documentElement.style.setProperty('--ag-odd-row-background-color', "#F4F4F4");
    document.documentElement.style.setProperty('--ag-header-background-color', "#F9F9F9");
    document.documentElement.style.setProperty('--ag-foreground-color', "#272727");
    document.documentElement.style.setProperty('--ag-secondary-foreground-color', "#272727");
    document.documentElement.style.setProperty('--ag-header-foreground-color', "#272727");
    document.documentElement.style.setProperty('--ag-border-color', "#E2E2E2");
    document.documentElement.style.setProperty('--ag-secondary-border-color', "#E2E2E2");
    document.documentElement.style.setProperty('--ag-header-cell-hover-background-color', "#F1F1F1");
    document.documentElement.style.setProperty('--ag-row-hover-color', "#F1F1F1");

}
