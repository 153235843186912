import { Card } from "antd"
import { Typography, Box } from "@mui/material"
import SearchUser from "./SearchUser"
import { useOutletContext } from "react-router-dom";


export function DynamicRequestorSelect({ label = null, fieldName = null, description = null }) {
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();

    return (
        <Card
            style={{ marginTop: 20, marginLeft: 0 }}
            title={<Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                Select Requestor
            </Typography>}
        >
            <Box sx={{mt:2}}>
                <SearchUser
                    FieldName={label ? label : "DYNAMIC_REQUESTOR"}
                    FieldLabel={fieldName ? fieldName : "Select Requestor for this access?"}
                />
            </Box>
                        {/* choose a requestor */}
            <Typography sx={{ fontSize: 12 }}>
                {description ? description : '* If you are requesting for someone else, select their email address.'}
            </Typography>

        </Card>
    )
}