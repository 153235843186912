import React from 'react';
import {
	Typography, useTheme, Box, CardContent, Link as MuiLink, Grid
} from "@mui/material";
import Card from 'antd/es/card/Card';
import Google from "../../Assets/Google.svg"
import AnimatedLogo from "../../Assets/Animated/Logo.svg"
import { Image,  Avatar, Button } from 'antd';
import Forbidden from './403Forbidden';

export default function Unauthorized({ message }) {
	const theme = useTheme();
	document.title = "Stork";

	let loginURL = process.env.REACT_APP_LOGIN_URL + `?redirect_url=${window.location.href}`


	// background-image: ;
	return (
		<Box
			sx={{
				textAlign: 'center', height: '100vh', width: '100%', display: 'flex',
				backgroundColor: "#fff",
				backgroundImage: "linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% )"
			}}

		>
			<Box sx={{ alignSelf: 'center', width: 'inherit' }} >
				{
					message &&
					message.includes("Not Enough Permissions") ? <Forbidden /> : <></>
				}
				{
					message ?
						(message.includes("Unable to login") || message.includes("Failed to fetch - Server Unreachable")) ?
							<>
								<Box>
									<Image
										width={300}
										src={AnimatedLogo}
										preview={false}
										controls={false}
										style={{

										}}
									/>
								</Box>
								
								<Box sx={{ mt: 16 }}>
									<Button
										// icon={Google}
										type="primary"
										shape="round"
										size={"large"}
										href={loginURL}
										style={{
											backgroundColor: '#171923',
											height: 50,
											textDecoration: "None"
										}
										}
									>
										<Grid container spacing={1} sx={{ alignItems: 'center' }}>
											<Grid item>
												<Avatar src={Google} />
											</Grid>
											<Grid item>
												<Typography sx={{ fontWeight: 600 }}>Continue With Google</Typography>

											</Grid>

										</Grid>
									</Button>
								</Box>
							</>
							:
							<>
								<Typography variant='h3' sx={{ my: 2, fontFamily: 'Exo, Roboto' }} >
									Unauthorized
								</Typography>
								<Typography variant='body2' sx={{ fontStyle: 'italic' }} >
									You do not have enough permissions to view this page.
								</Typography>
								<Typography variant='body2'>
									<MuiLink color={'inherit'} href='/'>Click here to go back to HOME</MuiLink>
								</Typography>
							</>
						:
						<>
							<Box>
								<Image
									width={300}
									src={AnimatedLogo}
									preview={false}
									controls={false}
									style={{

									}}
								/>
							</Box>
							
							<Box sx={{ mt: 16 }}>
								<Button
									// icon={Google}
									type="primary"
									shape="round"
									size={"large"}
									href={loginURL}
									// href={`${process.env.REACT_APP_LOGIN_URL}?redirect_url=${window.location.href}`}
									style={{
										backgroundColor: '#171923',
										height: 50,
										textDecoration: "None"
									}
									}
								>
									<Grid container spacing={1} sx={{ alignItems: 'center' }}>
										<Grid item>
											<Avatar src={Google} />
										</Grid>
										<Grid item>
											<Typography sx={{ fontWeight: 600 }}>Continue With Google</Typography>
										</Grid>
									</Grid>
								</Button>
							</Box>
						</>
				}
			</Box>
		</Box>
	)
}
