import React, { useState, useEffect } from 'react'
import { flashDataFetcher } from '../../../../Utils/flashHelpers';
import { Chip, Box } from '@mui/material';
import { Modal } from 'antd';
import { CircularProgress, Grid, Typography, Paper } from '@mui/material';
import { Divider } from 'antd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { EyeTwoTone } from '@ant-design/icons';

import { Descriptions } from 'antd';

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}


export default function QuickViewUser({ user_id, size }) {
  const umsURL = validateEmail(user_id) ? `user?company_email_id=${user_id}` : null
  const [loading, setLoading] = useState(false)
  const [userUMSData, setUMSData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);



  async function getUserData() {
    if (umsURL !== null) {
      flashDataFetcher(umsURL).then((data) => {
        if (data.length > 0) {
          setUMSData(data[0])
          setLoading(false)
        }
      }).catch((error) => {
        console.log("Error Happened")
      })
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      getUserData()
      // getUserGoogleProfile()
    }
  }, [isModalOpen])


  function handleClick() {
    setIsModalOpen(!isModalOpen)
  }


  const items = [
    {
      key: '1',
      span: 1,
      label: 'Department Name',
      children: userUMSData ? userUMSData.department_name : '',
    },
    {
      key: '2',
      span: 1,
      label: 'Designation',
      children: userUMSData ? userUMSData.designation : '',
    },
  ];

  const Manageritems = [
    {
      key: '1',
      span: 3,
      label: 'Full Name',
      children: userUMSData && userUMSData.manager ? userUMSData.manager.full_name : '',
    },
    {
      key: '2',
      span: 3,
      label: 'Employee ID',
      children: userUMSData && userUMSData.manager ? userUMSData.manager.employee_id : '',
    },
    {
      key: '3',
      span: 3,
      label: 'Email',
      children: userUMSData && userUMSData.manager ? userUMSData.manager.company_email_id : '',
    },
  ];

  return (
    <>
      <Modal
        // title="User Details"
        // loading={loading}
        open={isModalOpen}
        onCancel={handleClick}
        footer={null}

      >
        {
          !loading ?
            <Box sx={{ mt: 4 }}>
              {
                (!userUMSData && userUMSData.length < 1) ?
                  <Box sx={{ textAlign: 'center' }}><CircularProgress color="primary" /></Box>
                  : <>
                    <Grid container>

                      <Grid item xs={12}>
                        <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 600 }}>
                          {userUMSData ? userUMSData.full_name : ''}
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="h5" sx={{ fontSize: 14, fontWeight: 400 }}>
                            {userUMSData ? userUMSData.employee_id : ''}
                          </Typography>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="h5" sx={{ fontSize: 14, fontWeight: 400 }}>
                            {userUMSData ? userUMSData.company_email_id : ''}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                      <Descriptions
                        layout="vertical"
                        items={items}
                      />
                    </Box>
                    <Divider />

                    <Box sx={{ mt: 1 }}>
                      <Grid item xs={12}>
                        <Typography variant="h5" sx={{ fontSize: 16, fontWeight: 600 }}>
                          Manager Details
                        </Typography>
                        <Box sx={{ mt: 3 }}>
                          <Descriptions
                            layout="horizontal"
                            items={Manageritems}
                          />
                        </Box>
                      </Grid>

                    </Box>
                  </>
              }
            </Box> : <CircularProgress />
        }
      </Modal>
      <Box>
        <Chip
          sx={{}}
          size={size ? size : 'medium'}
          label={user_id}
          deleteIcon={<VisibilityIcon />}
          onDelete={handleClick}
          onClick={handleClick}
          variant="outlined"
        />
      </Box>
    </>
  )
}


function EmployeeQuickView({ email_id, isModalOpen, setIsModalOpen }) {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const umsURL = validateEmail(email_id) ? `user?company_email_id=${email_id}` : null

  const [loading, setLoading] = useState(false)
  const [userUMSData, setUMSData] = useState([])


  function handleClick() {
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    if (isModalOpen) {
      getUserData()
      // getUserGoogleProfile()
    }
  }, [isModalOpen])


  async function getUserData() {
    if (umsURL !== null) {
      flashDataFetcher(umsURL).then((data) => {
        if (data.length > 0) {
          setUMSData(data[0])
          setLoading(false)
        }
      }).catch((error) => {
        console.log("Error Happened")
      })
    }
  }


  const items = [
    {
      key: '1',
      span: 1,
      label: 'Department Name',
      children: userUMSData ? userUMSData.department_name : '',
    },
    {
      key: '2',
      span: 1,
      label: 'Designation',
      children: userUMSData ? userUMSData.designation : '',
    },
  ];

  const Manageritems = [
    {
      key: '1',
      span: 3,
      label: 'Full Name',
      children: userUMSData && userUMSData.manager ? userUMSData.manager.full_name : '',
    },
    {
      key: '2',
      span: 3,
      label: 'Employee ID',
      children: userUMSData && userUMSData.manager ? userUMSData.manager.employee_id : '',
    },
    {
      key: '3',
      span: 3,
      label: 'Email',
      children: userUMSData && userUMSData.manager ? userUMSData.manager.company_email_id : '',
    },
  ];

  return (
    <Modal
      // title="User Details"
      // loading={loading}
      open={isModalOpen}
      onCancel={handleClick}
      footer={null}
    >
              {
          !loading ?
            <Box sx={{ mt: 4 }}>
              {
                (!userUMSData && userUMSData.length < 1) ?
                  <Box sx={{ textAlign: 'center' }}><CircularProgress color="primary" /></Box>
                  : <>
                    <Grid container>

                      <Grid item xs={12}>
                        <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 600 }}>
                          {userUMSData ? userUMSData.full_name : ''}
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="h5" sx={{ fontSize: 14, fontWeight: 400 }}>
                            {userUMSData ? userUMSData.employee_id : ''}
                          </Typography>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="h5" sx={{ fontSize: 14, fontWeight: 400 }}>
                            {userUMSData ? userUMSData.company_email_id : ''}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                      <Descriptions
                        layout="vertical"
                        items={items}
                      />
                    </Box>
                    <Divider />

                    <Box sx={{ mt: 1 }}>
                      <Grid item xs={12}>
                        <Typography variant="h5" sx={{ fontSize: 16, fontWeight: 600 }}>
                          Manager Details
                        </Typography>
                        <Box sx={{ mt: 3 }}>
                          <Descriptions
                            layout="horizontal"
                            items={Manageritems}
                          />
                        </Box>
                      </Grid>

                    </Box>
                  </>
              }
            </Box> : <CircularProgress />
        }
    </Modal>
  )
}


export function QuickViewUserApprover({ email_id }) {
  const umsURL = validateEmail(email_id) ? `user?company_email_id=${email_id}` : null
  const [umsData, setUMSData] = useState()
  const [loading, setLoading] = useState(true)


  const [isModalOpen, setIsModalOpen] = useState(false)

  async function getUserData() {
    if (umsURL !== null) {
      flashDataFetcher(umsURL).then((data) => {
        if (data.length > 0) {
          setUMSData(data[0])
          setLoading(false)
        }
      }).catch((error) => {
        console.log("Error Happened")
      })
    }
  }


  useEffect(() => {

    getUserData()

  }, [])
  return (
    <>
      <Box>
        {
          <EmployeeQuickView email_id={email_id} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
          // EmployeeQuickView({ email_id, isModalOpen, setIsModalOpen })
        }
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={8}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>{umsData ? umsData.full_name : email_id}</Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 400 }}>{umsData ? umsData.designation : ''}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            <EyeTwoTone onClick={() => setIsModalOpen(true)} style={{ fontSize: 18 }} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
