import React, { useEffect, useState } from 'react';
import Card from 'antd/es/card/Card';
import { Typography, Grid, Box } from '@mui/material';
import TextArea from 'antd/es/input/TextArea';
import { Form, Input, Select, DatePicker, Space, Button, Radio, Checkbox, Spin } from "antd";
import { notification } from "antd";
import { gendersAll, bloodGroups, phonePeDomains, phonePeEntities, USBaccessList, companies } from '../../../../../Utils/constants';
import SearchUser from '../../../Components/Global/SearchUser';
import { useOutletContext } from "react-router-dom";
import { EndDateValid, createFlashRequest, disabledDate, disabledDateTime, validateEmail } from '../../../../../Utils/flashHelpers';
import { DynamicRequestorSelect } from '../../../Components/Global/DynamicRequestor';
import AccessRequestConfirmationDialog from '../../Confirmation/AccessRequestConfirmationDialog';

export default function CreateLaptopAdminAccessRequest() {
    const [form] = Form.useForm();
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();
    const [triggerSubmission, setTriggerSubmission] = useState(false);
    const [values, setValues] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);


    const onFinish = (values) => {

        setValues(values);
        setTriggerSubmission(true);
        setLoading(false);
    }


    return (
        <Grid container spacing={2}>
            {contextHolder}
            <Grid item xs={10}>
                <Card title="Request for administrator permission for Laptop">
                    {triggerSubmission ? (
                        <AccessRequestConfirmationDialog
                            triggerSubmission={triggerSubmission}
                            setTriggerSubmission={setTriggerSubmission}
                            request_title={"Request for administrator permission for Laptop"}
                            entity_type={"CREATE"}
                            component_name={"LAPTOP_ADMIN_ACCESS"}
                            state={"created"}
                            parameters={values}
                            requestor={values ? values.DYNAMIC_REQUESTOR : null}
                        />
                    ) : null}
                    <Form
                        onFinish={onFinish}
                        layout="vertical"
                        initialValues={{
                            DYNAMIC_REQUESTOR: loginUser.user_email,
                        }}
                    >
                     {<DynamicRequestorSelect />}
                    
                        <AccessDetailsSubmission />
                        <ScopeofWork/>
                        <BusinessJustification />

                        <Form.Item>
                            <Box sx={{ mt: 4, textAlign: "right" }}>
                                <Button
                                    disabled={loading}
                                    htmlType="submit"
                                    style={{ width: 200, height: 40 }}
                                    type="primary"
                                >
                                    {loading ? (
                                        <Spin />
                                    ) : (
                                        <Typography sx={{ fontWeight: 600 }}>
                                            Submit & Request
                                        </Typography>
                                    )}
                                </Button>
                            </Box>
                        </Form.Item>
                    </Form>
                </Card>
            </Grid>
        </Grid>
    );
}
function ScopeofWork() {
    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                Scope of Work
            </Typography>}
        >
            <Grid container spacing={2}>
               
                <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Box>
                        <Form.Item
                            name="HOST_NAME"
                            rules={[
                                {
                                    required: true,
                                    message: 'Host Name cannot be empty!',
                                },
                            ]}
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Host Name
                            </Typography>}
                        >
                            <Input
                                placeholder="Enter Host Name of the Laptop"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}
function AccessDetailsSubmission() {
    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                Access Details
            </Typography>}
        >
            <Grid container spacing={2}>
            
            <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                        <Form.Item
                            name="START_DATE"
                            rules={[
                                {
                                    required: true,
                                    message: 'Start Date cannot be empty!',
                                },
                            ]}
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Start Date
                            </Typography>}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                disabledDate={disabledDate}
                                disabledTime={disabledDateTime}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                        <Form.Item
                            name="END_DATE"
                            rules={[
                                {
                                    required: true,
                                    message: 'End Date cannot be empty!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject('End Date is required!');
                                        }
                                        const startDate = getFieldValue('START_DATE');
                                        if (!startDate) {
                                            return Promise.reject('Please select a Start Date first!');
                                        }

                                        const maxEndDate = startDate.clone().add(1, 'year');
                                        if (value.isAfter(maxEndDate)) {
                                            return Promise.reject('End Date must be within one year of the Start Date!');
                                        }

                                        if (value.isBefore(startDate)) {
                                            return Promise.reject('End Date cannot be before the Start Date!');
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                End Date
                            </Typography>}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                disabledDate={disabledDate}
                                disabledTime={disabledDateTime}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Box>
                </Grid>
                
            </Grid>
        </Card>
    );
}
function BusinessJustification() {
    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                Business Justification
            </Typography>}
        >
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Box>
                        <Form.Item
                            name="JUSTIFICATION"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Business Justification
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea
                                placeholder="Provide Justification for all mentioned accesses above."
                                autoSize={{
                                    minRows: 4,
                                    maxRows: 6,
                                }}
                            />
                        </Form.Item>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}
