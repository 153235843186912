import AWS from "../../../Assets/Aws.svg"
import AuditorIcon from "../../../Assets/AuditorIcon.png"
import InstantMessaging from "../../../Assets/InstantMessaging.png"
import WhatsAppIcon from "../../../Assets/WhatsAppIcon.png"
import TimeTable from "../../../Assets/TimeTable.png"
import Software from '../../../Assets/Software.jpg'
import mail from '../../../Assets/mail.jpg'
import usb from "../../../Assets/usb.png"
import doc_share from "../../../Assets/doc_share.png"
import admin_access from "../../../Assets/admin_access.png"



// Application Categories
export const ApplicationCategories = [
    {
        "category_code": "HR_MANAGEMENT",
        "category_name": "HR Management"
    },
    {
        "category_code": "IT",
        "category_name": "IT Access"
    },
]

export const FormTiles = [
    {
        "block_title": "HR Management",
        "block_category_code": "HR_MANAGEMENT",
        "tiles" : [
            {
                "application_title": "Auditor Onboarding",
                "icon": AuditorIcon,
                "route": "auditor-create",
                "description": "Auditor Onboarding",
                "state": true,
                "button_text": "Request Onboarding"
            },
            {
                "application_title": "External User Onboarding",
                "icon": AuditorIcon,
                "route": "external-onboarding",
                "description": "External Employee Onboarding",
                "state": true,
                "button_text": "Request Onboarding"
            },
            {
                "application_title": "Access Extenison",
                "icon": TimeTable,
                "route": "access-extension",
                "description": "Access Extension Requests",
                "state": true,
                "button_text": "Request Extension"
            },
        ]
    },

    {
        "block_title": "IT Access",
        "block_category_code": "IT",
        "tiles" : [
            {
                "application_title": "Instant Messaging",
                "icon": WhatsAppIcon,
                "route": "instant-messaging",
                "description": "WhatsApp, Skype Requests",
                "state": true,
                "button_text": "Request Instant Messaging"
            },
            {
                "application_title": "USB Access",
                "icon": usb,
                "route": "usb-access",
                "description": "USB Access Request",
                "state": true,
                "button_text": "Request USB Access"
            },
             
            {
                "application_title": "Request for Mail Transfer",
                "icon": mail,
                "route": "mail-transfer",
                "description": "Request for Mail Transfer",
                "state": true,
                "button_text": "Request for Mail Transfer"
            },
            {
                "application_title": "Request for Admin permissions",
                "icon": admin_access,
                "route": "admin-access",
                "description": "Request for Admin permissions",
                "state": true,
                "button_text": "Request for Admin permissions"
            },
            {
                "application_title": "Software/Plugins Install",
                "icon": Software,
                "route": "software-install",
                "description": "Software/Plugins Installation Request",
                "state": true,
                "button_text": "Request For Software/Plugins Install"
            },
            {
                "application_title": "Share Document Externally Request Details",
                "icon": doc_share,
                "route": "share-doc",
                // "description": "Request for Share Document Externally",
                "state": true,
                "button_text": "Request for Share Document Externally"
            },
        ]
    },
]
