import { useRoutes } from "react-router-dom";
import { CommonRoutes } from "./CommonRoutes";
import { ErrorRoutes } from "./ErrorRoutes";
import { AdminRoutes } from "./AdminRoutes";

export default function Routes({ loginUser, handleLogout, handleModeChange, handleTokenError }) {
    var routes = [ErrorRoutes()];

    if(loginUser.scopes.includes("stork.hr_read")){
		routes=[AdminRoutes(loginUser, handleLogout, handleModeChange, handleTokenError), ErrorRoutes()];
	}else if (loginUser.scopes.includes("iam.stork_admin")){
		routes=[AdminRoutes(loginUser, handleLogout, handleModeChange, handleTokenError), ErrorRoutes()];
	} else {
		routes=[CommonRoutes(loginUser, handleLogout, handleModeChange, handleTokenError), ErrorRoutes()];
	}
	// 
    return useRoutes(routes);
}
