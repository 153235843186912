import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Card, Divider } from 'antd';
import QuickViewUser from '../../../Components/Global/QuickViewUser';

export default function SoftwareInstallView({ requestData, parameters }) {
  return (
    <Card title="Software/Plugins Installation Request Details">
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Request Raised For</Typography>
            <Box sx={{ mt: 1 }}>
              <QuickViewUser user_id={requestData?.parameters.DYNAMIC_REQUESTOR} />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Software Details</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.SOFTWARE_DETAILS}
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Plugins Details</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.PLUGINS_DETAILS}
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Justification</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.JUSTIFICATION}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
