import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline, Box, Backdrop, CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme, agGridTheme } from "./Utils/theme";
import { handleLogin } from "./Utils/helpers";
import Routes from "./Routes";
import Unauthorized from "./Components/Errors/401Unauthorized";
import ServerError from "./Components/Errors/500ServerError";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spin } from "antd";
import Cookies from "js-cookie";


function App() {
    const [accessTokenLoaded, setAccessTokenLoaded] = useState(false);
    const [accessTokenError, setAccessTokenError] = useState("");
    const [loginUser, setLoginUser] = useState({});
    const [mode, setMode] = useState("light");


    function handleModeChange(newMode) {
        // setMode(newMode);
        // agGridTheme(newMode);
        // localStorage.setItem("colorMode", newMode);
    }

    function handleTokenLoaded(value) {
        setAccessTokenLoaded(value);
    }

    function handleTokenError(value) {
        setAccessTokenError(value);
    }

    function handleLoginUser(data) {
        setLoginUser(data);
    }

    function handleLogout() {
        localStorage.removeItem('accessToken');
        // localStorage.setItem('accessToken', null)
    }

    // For Local Development Comment this Code
    // Code is to fix, race condition between Attempt to login
    // and X-Access-Token being pushed by nginx

    const [LoadingOverlay, setLoadingOverlay] = useState(false)

    function handleLoadingOverlay(value) {
        setLoadingOverlay(value)
    }

    // if (getCookie('X-Access-Token') == ''){
    //     setLoadingOverlay(true)
    //     window.location.reload()
    // }

    function OverlayBackDrop() {
        return (
            <Backdrop sx={{ zIndex: 9999, backgroundColor: "#fff" }} open>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    //  Till Here

    useEffect(() => {
        handleLogin(handleTokenLoaded, handleTokenError, handleLoginUser, handleLoadingOverlay);
        
    }, []);

    const accessTokenResult = () => {
        if (accessTokenError.length === 0) {
            return (
                <Router>
                    <Routes
                        mode={mode}
                        loginUser={loginUser}
                        handleModeChange={handleModeChange}
                        handleLogout={handleLogout}
                        handleTokenError={handleTokenError} />
                </Router>
            )
        } else if (accessTokenError.toLowerCase().includes("not enough permissions")) {
            return LoadingOverlay ? <OverlayBackDrop /> : <Unauthorized message={accessTokenError} />
        } else if (
            accessTokenError.toLowerCase().includes("unable to login") ||
            accessTokenError.toLowerCase().includes("server unreachable") ||
            accessTokenError.toLowerCase().includes("not enough permissions")
        ) {
            return LoadingOverlay ? <OverlayBackDrop /> : <Unauthorized message={accessTokenError} />
        } else if (accessTokenError.toLowerCase().includes("failed to fetch")) {
            return <ServerError message={accessTokenError} />
        } else {
            return <Unauthorized message={accessTokenError} />
        }
    }

    return (
        <ThemeProvider theme={lightTheme}>
            <ToastContainer />
            <Box sx={{ display: 'flex' }} >
                {
                    accessTokenLoaded ?
                        accessTokenResult()
                        :
                        <Backdrop sx={{ zIndex: 9999, backgroundColor: "#fff" }} open>
                            <Spin fullscreen />
                        </Backdrop>
                }
            </Box>
        </ThemeProvider>
    );
}

export default App;
