import React, { useEffect, useState } from "react";
import { Box, Fab, Paper, Typography } from "@mui/material";
import { Modal, Spin, Alert, notification } from "antd";
import { createFlashRequest, rulesEngineCheck } from "../../../../Utils/flashHelpers";
import ResultArea from "./ResultArea";


export default function AccessRequestConfirmationDialog({
  triggerSubmission,
  setTriggerSubmission,
  request_title,
  entity_type,
  component_name,
  state,
  parameters,
  requestor,
}) {

  let data = {
      request_title: request_title ? request_title : null,
      entity_type: entity_type ? entity_type : null,
      component_name: component_name ? component_name : null,
      state: state ? state : null,
      parameters: parameters,
      requestor: parameters.DYNAMIC_REQUESTOR ? parameters.DYNAMIC_REQUESTOR : requestor,
  };

  const [processing, setProcessing] = useState();
  const [violations, setViolations] = useState();
  const [violationsFound, setViolationsFound] = useState(false);
  const [submittingRequest, setIsSubmittingRequest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [requestUUID, setRequestUUID] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [disableOkButton, setDisableOkButton] = useState(false);


  async function validateRequestData() {
    setProcessing(true);
    rulesEngineCheck(`rules/check`, data).then((result) => {
      if (result) {
        if (result.status === "success") {
          if (result.data.length > 0) {
            setViolations(result.data);
            setViolationsFound(true);
            setProcessing(false);
          } else {
            setProcessing(true);
            createRequestOnWizard(data);
          }
        } else {
          api.error({
            message: "Something went wrong with Stork - UMS",
            description: `${result.message}`,
          });
          setProcessing(false)
        }
      } else {
        api.error({
          message: "Something went wrong with Stork - UMS",
          description: `${result.message}`,
        });
      }
    });
  }


  async function createRequestOnWizard() {
    setIsSubmittingRequest(true);
    createFlashRequest(data).then((request) => {
      if (request) {
        if (request.status === "success") {
          setSuccess(true);
          setRequestUUID(request.uuid);
          setProcessing(false);
          setDisableOkButton(true);
          setIsSubmittingRequest(false);
        } else {
          api.error({
            message: "Something went wrong with Stork - UMS",
            description: `${request.message}`,
          });
        }
      } else {
        api.error({
          message: "Something went wrong with Stork - UMS",
          description: `${request.message}`,
        });
      }
    });
  }

  function DisableAllowSubmission() {
    // Submission is allowed only if there is no conflict with deny.
    if (violations && violations.length > 0) {
      if (violations[0].VIOLATION_PARAMETERS.action === "DENY_ACCESS") {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }


  useEffect(() => {
    validateRequestData()


  }, [])

  return (
    <>
      {contextHolder}

      <Modal
        centered
        open={triggerSubmission}
        onOk={() => createRequestOnWizard()}
        onCancel={() => setTriggerSubmission(false)}
        okText={
          <Typography sx={{ fontWeight: 600 }}>
            {submittingRequest ? <Spin /> : "Confirm and Continue"}
          </Typography>
        }
        width={1000}
        okButtonProps={{
          disabled:
            DisableAllowSubmission() || disableOkButton || submittingRequest,
        }}
        cancelButtonProps={{
          disabled: false,
        }}
      >
        {success ? (
          <ResultArea
            status="success"
            title="Request Created Successfully!"
            subTitle="Your request has been created successfully! Refer below what happens next"
            darkButtonLink={`/request/view/${requestUUID}`}
            // lightButtonLink={"/"}
            // lightButtonText="Home"
            darkButtonText="View Request"
            pointsToShare={[
              "Your request will be assigned to different set of approvers",
              "Post, approvals your request will be sent to IT for Provisioning",
              "You will recieve notifications for all steps.",
              "Please, reach out to INFOSEC-IAM Team, or UMS OnCall.",
            ]}
          />
        ) : (
          <></>
        )}
        <Box sx={{ mt: 4, mb: 4 }}>
          {processing ? <LoadingDialog /> : <></>}
        </Box>

        {!success ? (
          violations && violations.length > 0 ? (
            <ConflictsDetected violations={violations} />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Modal>
    </>
  )
}


function LoadingDialog() {
  return (
    <Box sx={{ mt: 4, mb: 4, textAlign: "center" }}>
      <Box sx={{ mb: 4 }}>
        <Spin size="large" />
      </Box>
      <Typography>Hold on! UMS is processing your request.</Typography>
    </Box>
  );
}

export function ConflictsDetected({ violations }) {
  return (
    <Box>
      <Box sx={{ mt: 2 }}>
        <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
          UMS Detected following Issues with this access request
        </Typography>
      </Box>
      <Box sx={{ mb: 5, mt: 4 }}>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            Primary Violation
          </Typography>
          <Box>
            {violations && violations.length > 0 ? (
              <PrimaryViolation
                rule={violations && violations.length > 0 ? violations[0] : []}
              />
            ) : (
              <></>
            )}
          </Box>
        </Paper>
        <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            All Violations
          </Typography>
          <Box>
            {violations && violations.length > 0 ? (
              violations.map((rule, index) => {
                return (
                  <Box sx={{ mt: 2 }}>
                    <Alert
                      message={
                        <>
                          <Typography sx={{ fontWeight: 400, fontSize: 14 }}>
                            {getMessageRuleHeading(
                              rule?.VIOLATION_PARAMETERS.action
                            )}{" "}
                            {rule.VIOLATION_PARAMETERS.message}
                          </Typography>
                          {
                            rule.VIOLATION_PARAMETERS.action === "REQUIRED_EXCEPTION_APPROVAL" ?
                              rule.VIOLATION_PARAMETERS.exception_approvers ? (
                                <Typography sx={{ fontSize: 12 }}>
                                  List of Exception approvers ({" "}
                                  {rule.VIOLATION_PARAMETERS.exception_approvers} )
                                </Typography>
                              ) : (
                                <></>
                              )
                              : <></>
                          }
                        </>
                      }
                      type={getRuleColor(rule?.VIOLATION_PARAMETERS.action)}
                    />
                  </Box>
                );
              })
            ) : (
              <></>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export function PrimaryViolation({ rule }) {
  return (
    <Box sx={{ mt: 2 }}>
      <Alert
        message={
          <Typography sx={{ fontWeight: 600 }}>
            {getRuleHeading(rule?.VIOLATION_PARAMETERS.action)}{" "}
          </Typography>
        }
        description={
          <>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontSize: 14 }}>
                {rule.VIOLATION_PARAMETERS.message}
              </Typography>
            </Box>
            {
              rule.VIOLATION_PARAMETERS.action === "REQUIRED_EXCEPTION_APPROVAL" ?
                rule.VIOLATION_PARAMETERS.exception_approvers ? (
                  <Typography sx={{ fontSize: 12 }}>
                    List of Exception approvers ({" "}
                    {rule.VIOLATION_PARAMETERS.exception_approvers} )
                  </Typography>
                ) : (
                  <></>
                )
                : <></>
            }
          </>
        }
        type={getRuleColor(rule.VIOLATION_PARAMETERS.action)}
        showIcon
      />
    </Box>
  );
}

export function getRuleHeading(action) {
  switch (action) {
    case "DENY_ACCESS":
      return "This access request has been blocked.!";
    case "REQUIRED_EXCEPTION_APPROVAL":
      return "This access needs additional approvals.!";
    case "TRIGGER_NOTIFICATION":
      return "This access will be notified to following people.";
    case "RECOMMENDATION_TRIGGER":
      return "We have some reccomentation for you.";
    default:
      return "None";
  }
}

export function getMessageRuleHeading(action) {
  switch (action) {
    case "DENY_ACCESS":
      return "BLOCKED | ";
    case "REQUIRED_EXCEPTION_APPROVAL":
      return "ADDITIONAL APPROVALS NEEDED | ";
    case "TRIGGER_NOTIFICATION":
      return "INFO | ";
    case "RECOMMENDATION_TRIGGER":
      return "RECOMMENDATION | ";
    default:
      return "None";
  }
}

export function getRuleColor(action) {
  switch (action) {
    case "DENY_ACCESS":
      return "error";
    case "REQUIRED_EXCEPTION_APPROVAL":
      return "warning";
    case "TRIGGER_NOTIFICATION":
      return "info";
    case "RECOMMENDATION_TRIGGER":
      return "info";
    default:
      return "info";
  }
}

