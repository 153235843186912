import React from 'react'
import QuickViewUser from '../../../Components/Global/QuickViewUser'
import { Card, Divider } from 'antd'
import { Grid, Typography, Box } from '@mui/material'


export default function ViewExternalEmployeeRequest({ requestData, parameters }) {
    return (
        <>
            <Card
            >
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600 }}>Requestor</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Requested By</Typography>
                        <Box sx={{ mt: 1 }}>
                            <QuickViewUser user_id={requestData?.requestor} />
                        </Box>
                    </Grid>
                </Grid>

                <Divider />
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600 }}>Basic Details</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>First Name</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.FIRST_NAME : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Last Name</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.LAST_NAME : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Full Name</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.FULL_NAME : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>

                </Grid>

                <Grid container spacing={4} sx={{mt:1}}>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Gender</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.GENDER : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Blood Group</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.BLOOD_GROUP : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Divider />

                <Grid container spacing={4} sx={{mt:1}}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Personal Email ID</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.AGENCY_EMAIL_ADDRESS : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Agency Phone Number</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.AGENCY_PHONE_NUMBER : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Divider/>
                <Grid container spacing={4} sx={{mt:1}}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Start Date</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.START_DATE : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>End Date</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.END_DATE : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Divider/>
                <Grid container spacing={4} sx={{mt:1}}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>PhonePe Entity</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.PHONEPE_ENTITY : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} >
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>PhonePe Manager</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.PHONEPE_MANAGER : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Email Domain</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.EMAIL_DOMAIN : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Divider/>
                <Grid container spacing={4} sx={{mt:1}}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Agency Name</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.AGENCY_NAME : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>List of Accesses Needed</Typography>
                        <Box sx={{ mt: 1 }}>
                        <Typography sx={{fontSize: 14, fontWeight: 400}}>
                        {parameters ? 
                        parameters.LIST_OF_ACCESSES_NEEDED.map((access) => {
                            return <Typography>{access}</Typography>
                        }) 
                        
                        : 'N/A'}
                </Typography> 
                        </Box>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container spacing={4} sx={{mt:1}}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Justification</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                {parameters ? parameters.JUSTIFICATION : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}
