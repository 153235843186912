import React from 'react';
import './403Forbidden.css'
import {Container, Link as MuiLink, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";

export default function Forbidden() {
    document.title = "UMS - FORBIDDEN";
    return (
        <div className={"forbidden-container"}>
            <Container sx={{mt: 2}}>
                <Typography variant={'h1'} sx={{textAlign: 'center'}} color={grey[200]}>
                    403 FORBIDDEN
                </Typography>
                <Typography variant={'subtitle1'} sx={{textAlign: 'center'}} color={grey[300]}>
                    You do not have enough permissions to view this page.
                </Typography>
                {/*<Typography variant={'subtitle2'} sx={{textAlign: 'center'}} color={'white'}>*/}
                    <Typography variant='body2'  sx={{textAlign: 'center', fontStyle: 'italic'}} color={grey[300]}>
                        <MuiLink color={'inherit'} href='/'>Click here to go back to HOME</MuiLink>
                    </Typography>
                {/*</Typography>*/}
            </Container>
            <div className="ghost">
                <div className="ghost--navbar"></div>
                <div className="ghost--columns">
                    <div className="ghost--column">
                        <div className="code"></div>
                        <div className="code"></div>
                        <div className="code"></div>
                        <div className="code"></div>
                    </div>
                    <div className="ghost--column">
                        <div className="code"></div>
                        <div className="code"></div>
                        <div className="code"></div>
                        <div className="code"></div>
                    </div>
                    <div className="ghost--column">
                        <div className="code"></div>
                        <div className="code"></div>
                        <div className="code"></div>
                        <div className="code"></div>
                    </div>

                </div>
                <div className="ghost--main">
                    <div className="code"></div>
                    <div className="code"></div>

                </div>

            </div>

            <h1 className="police-tape police-tape--1">
                &nbsp;&nbsp;&nbsp;&nbsp;Error: 403&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Error:
                403&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Error: 403&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Error:
                403&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Error: 403&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Error:
                403&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Error: 403
            </h1>
            <h1 className="police-tape police-tape--2">Forbidden&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Forbidden&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Forbidden&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Forbidden&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Forbidden&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Forbidden&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h1>


        </div>
    )
}






