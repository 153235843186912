import React from 'react';

import { Outlet } from "react-router-dom";
import NotFound from "../Components/Errors/404NotFound";
import Unauthorized from "../Components/Errors/401Unauthorized";
import Forbidden from "../Components/Errors/403Forbidden";

export const ErrorRoutes = () => ({
    path: '/',
    element: <Outlet />,
    children: [
        {
            path: '/signout',
            element: <Unauthorized />,
        },
        {
            path: '/forbidden',
            element: <Forbidden />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]
});
