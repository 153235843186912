import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Card, Divider } from 'antd';
import QuickViewUser from '../../../Components/Global/QuickViewUser';

export default function ShareDocExternalView({ requestData, parameters }) {
  return (
    <Card title="Share Document Externally Request Details">
      <Box>
         <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Request Raised For</Typography>
            <Box sx={{ mt: 1 }}>
              <QuickViewUser user_id={requestData?.parameters.DYNAMIC_REQUESTOR} />
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Start Date of Access</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.START_DATE}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>End Date of Access</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.END_DATE}
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Box>
      <Box>
 <Grid container spacing={4}>
      <Grid item xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Drive Link</Typography>
            <Box sx={{ mt: 1 }}>
              <a href={requestData?.parameters.DRIVE_LINK} target="_blank" rel="noopener noreferrer">
                {requestData?.parameters.DRIVE_LINK}
              </a>
            </Box>
          </Grid>
      </Grid>
       <Divider />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Agreement Signed</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.AGREEMENT_SIGNED}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Vendor Domain</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.VENDOR_DOMAIN}
            </Box>
          </Grid>
        </Grid>
                <Divider />

          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>PII Confirmation</Typography>
            <Box sx={{ mt: 1 }}>
              {
                requestData?.parameters.PII_CONFIRMATION === "PII_YES" ?
                <Typography sx={{ fontSize: 14 }}><strong>Yes,</strong>I will be sharing PII / Sensitive Data via the document that I have shared outside.</Typography> : <></>
              }
                            {
                requestData?.parameters.PII_CONFIRMATION === "PII_NO" ?
                <Typography  sx={{ fontSize: 14 }}><strong>No,</strong> I will not be sharing PII / Sensitive Data via the document that I have shared outside.</Typography> : <></>
              }
            </Box>
          </Grid>

        <Divider />
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Justification</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.JUSTIFICATION}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}
