import { Card, Button, styled } from "@mui/material";
import MUIDataTable from 'mui-datatables';

const options = {
    shouldForwardProp: (prop) => !['hoverShadow', 'hoverColor'].includes(prop),
};

export const ShadowedCard = styled(Card, options, )(({ theme, hoverShadow = 1 }) => (
    {
        ':hover': {
            boxShadow: theme.shadows[hoverShadow],
        },
        borderRadius: '6px',
        backgroundColor: 'transparent',
        backdropFilter: 'blur(20px)'
    }
));

export const ShadowedButton = styled(Button, options, )(({ theme, hoverShadow = 1, hoverColor }) => (
    {
        ':hover': {
            boxShadow: theme.shadows[hoverShadow],
            opacity: 0.9,
            backgroundColor: hoverColor
        },
    }
));

export const ShadowedDataTable = styled(MUIDataTable, options, )(({ theme, hoverShadow = 1 }) => (
    {
        ':hover': {
            boxShadow: theme.shadows[hoverShadow],
        },
        borderRadius: '8px',
        // backgroundColor: 'transparent',
        // backdropFilter: 'blur(20px)'
    }
));
