import React from 'react'
import { Link, Typography } from "@mui/material";

export function handleLogin(handleTokenLoaded, handleTokenError, handleLoginUser, handleLoadingOverlay) {
    handleTokenLoaded(false);
    const controller = new AbortController();
    
    // 10 second timeout:
    const timeoutLogin = setTimeout(() => controller.abort(), 5000);

    let loginCookie = getCookie('IAM-IM-TOKEN')

      if ((loginCookie == ""  || loginCookie == undefined)){
        clearTimeout(timeoutLogin);
        handleTokenError(`Unable to login`);
        handleTokenLoaded(true);
        handleLoginUser(false)
        
    } else {
        fetch(process.env.REACT_APP_AUTH_BASE_URL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: 'same-origin',
            redirect: "follow",
            referrerPolicy: "no-referrer",
            headers: {
                'X-ACCESS-TOKEN': loginCookie
            },
            signal: controller.signal
    
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status >= 500 || response.status === 401 || response.status === 429) {
                        throw new Error(`Unable to login - ${response.status}: ${response.statusText}`);
                    }
                } 
                return response.json()
            })
            .then(
                (result) => {
                    clearTimeout(timeoutLogin);
                    localStorage.setItem("accessToken", result.access_token.token);
                    result.user.scopes = result.access_token.scopes;
                    handleTokenError("")
                    handleLoginUser(result.user);
                    handleTokenLoaded(true);
                    handleLoadingOverlay(false)
                }
            )
            .catch(
                (error) => {
                    clearTimeout(timeoutLogin);
                    if (error.message.includes("abort")) {
                        handleTokenError("Server Unreachable");
                    } else {
                        console.log(`${error.message}`)
                        handleTokenError(`Unable to login - 401: Unauthorized`);
                    }
                    handleTokenLoaded(true);
                }
            );
    }
}



export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

export async function callBackend(
        url,
        beforeSendCallback=(() => {}),
        completeCallback=(() => {}),
        method="GET",
        body=null
    ) {
    const response = await fetch(process.env.REACT_APP_BASE_URL + url, {
        method: method,
        mode: "cors",
        cache: "no-cache",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
        beforeSend: function () {
            beforeSendCallback();
        },
        complete: function () {
            completeCallback();
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken")
        }
    });
    if (!response.ok) {
        if (response.status === 401) {
            // handleTokenError(error.message);
            throw new Error("Unauthenticated");
        } else if (response.status === 403) {
            throw new Error("Not Enough Permissions");
        } else if (response.status >= 500) {
            throw new Error(`Failed to call API (${url}) - ${response.status}: ${response.statusText}`);
        }
    }
    const data = await response.json();
    return data;
}


export async function dataFetcher(url, handleTokenError) {
    if (url) {
        return callBackend(url)
        .then(
            (result) => {
                return result["data"];
            }
        )
        .catch(
            (error) => {
                console.log(`error`, error);
                if (error.message.includes("Unauthenticated") || error.message.includes("Not Enough Permissions")) {
                    handleTokenError(error.message);
                }
                throw error;
            }
        );
    }
}

export async function dataPageFetcher(url, handleTokenError) {
    if (url) {
        return callBackend(url)
        .then(
            (result) => {
                return result;
            }
        )
        .catch(
            (error) => {
                console.log(`error`, error);
                if (error.message.includes("Unauthenticated") || error.message.includes("Not Enough Permissions")) {
                    handleTokenError(error.message);
                }
                throw error;
            }
        );
    }
}



export function capitalize(str, split_words=true) {
    let response = [];
    if (str) {
        if (split_words) {
            for (let word of str.split('_')) {
                response.push(word.charAt(0).toUpperCase() + word.slice(1))
            }
            return response.join(" ");
        } else {
            return str.charAt(0).toUpperCase() + str.slice(1)
        }
    } else {
        return str;
    }
}


// export function deleteAllCookies() {
//     let token = getCookie('X-Access-Token');
//     expireToken(token)

//     const expireDate = new Date(2147483647 * 1000).toUTCString();

//     let decodedCookie = decodeURIComponent(document.cookie);
//     let cookies = decodedCookie.split(';');
//     for (var i = 0; i < cookies.length; i++) {
//         var cookie = cookies[i];
//         var eqPos = cookie.indexOf("=");
//         var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
//     }
// }