import React, { useEffect, useState } from 'react';
import Card from 'antd/es/card/Card';
import { Typography, Grid, Box } from '@mui/material';
import TextArea from 'antd/es/input/TextArea';
import { Form, Input, Button, Spin , Select } from "antd";
import { notification } from "antd";
import { useOutletContext } from "react-router-dom";
import { EndDateValid, createFlashRequest, disabledDate, disabledDateTime, validateEmail } from '../../../../../Utils/flashHelpers';
import { DynamicRequestorSelect } from '../../../Components/Global/DynamicRequestor';
import AccessRequestConfirmationDialog from '../../Confirmation/AccessRequestConfirmationDialog';

export default function CreateMailTransferForm() {
    const [form] = Form.useForm();
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();
    const [triggerSubmission, setTriggerSubmission] = useState(false);
    const [values, setValues] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setValues(values);
        setTriggerSubmission(true);
        setLoading(false);
    }

    return (
        <Grid container spacing={2}>
            {contextHolder}
            <Grid item xs={10}>
                <Card title="Mail Transfer Request">
                    {triggerSubmission ? (
                        <AccessRequestConfirmationDialog
                            triggerSubmission={triggerSubmission}
                            setTriggerSubmission={setTriggerSubmission}
                            request_title={"Mail Transfer Request"}
                            entity_type={"CREATE"}
                            component_name={"MAIL_TRANSFER"}
                            state={"created"}
                            parameters={values}
                            requestor={values ? values.DYNAMIC_REQUESTOR : null}
                        />
                    ) : null}
                    <Form
                        onFinish={onFinish}
                        layout="vertical"
                        initialValues={{
                            DYNAMIC_REQUESTOR: loginUser.user_email,
                        }}
                    >
                        <DynamicRequestorSelect />
                        <MailTransferDetails />
                        <BusinessJustification />

                        <Form.Item>
                            <Box sx={{ mt: 4, textAlign: "right" }}>
                                <Button
                                    disabled={loading}
                                    htmlType="submit"
                                    style={{ width: 200, height: 40 }}
                                    type="primary"
                                >
                                    {loading ? (
                                        <Spin />
                                    ) : (
                                        <Typography sx={{ fontWeight: 600 }}>
                                            Submit & Request
                                        </Typography>
                                    )}
                                </Button>
                            </Box>
                        </Form.Item>
                    </Form>
                </Card>
            </Grid>
        </Grid>
    );
}

function MailTransferDetails() {
    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                Mail Transfer Details
            </Typography>}
        >
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                         <Form.Item
                            name="PREVIOUS_EMAIL_ADDRESS"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Previous Email Address
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Previous Email Address cannot be empty!',
                                },
                                {
                                    required: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    min: 5,
                                    message: "'Email' must be between 5 and 255 characters.",
                                },
                                {
                                    validator: validateEmail,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6}>
                    <Box>
                        <Form.Item
                            name="NEW_EMAIL_ADDRESS"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                New Email Address
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Email Address cannot be empty!',
                                },
                                {
                                    required: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    min: 5,
                                    message: "'Email' must be between 5 and 255 characters.",
                                },
                                {
                                    validator: validateEmail,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Box>
                        <Form.Item
                                label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                    Provide Confirmation Regarding PII / Sensitive Data
                                </Typography>}
                                name="CONFIRMATION"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select

                                    allowClear
                                >
                                    <Option value="CONFIRMATION_FOR_TRANSFER_TO_PERSONAL_EMAIL"><strong>No,</strong> Corporate sensitive data is being transferred in case of transfer to personal mail ids.</Option>
                                    <Option value="CONFIRMATION_FOR_TRANSFER_TO_NEW_JOINER"><strong>Yes,</strong> Transfer all emails and data, including corporate sensitive information, to the new Joiner's' email.</Option>

                                </Select>
                            </Form.Item>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}

function BusinessJustification() {
    return (
        <Card
            style={{ marginTop: 20 }}
            title={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                Business Justification
            </Typography>}
        >
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Box>
                        <Form.Item
                            name="JUSTIFICATION"
                            label={<Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Business Justification
                            </Typography>}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea
                                placeholder="Provide Justification for all mentioned accesses above."
                                autoSize={{
                                    minRows: 4,
                                    maxRows: 6,
                                }}
                            />
                        </Form.Item>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}
