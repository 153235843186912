import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';

import GsuiteProfile from '../GsuiteProfile';
import { useTheme } from '@mui/material/styles';
import { Grid, Paper } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));




export default function TopBar({ loginUser, handleModeChange, handleLogout, title, handleDrawerOpen, open, setOpen }) {
    const theme = useTheme();
    return (
        <Box sx={{ flexGrow: 1,    
        }}>
            <AppBar position="static" sx={{ backdropFilter: "blur(60px)", backgroundColor: '#FFF', color: '#1B0C30', p:1 }} elevation={0}>
                <Toolbar sx={{display:'flex' }} >

                        <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ mr: 2 }}
                                onClick={() => setOpen(!open)}
                                
                            >
                                <MenuIcon />
                            </IconButton>  

                <Box sx={{ flexGrow: 1 }}></Box>

                <Box sx={{ flexGrow: 0}}>
                    <GsuiteProfile
                        loginUser={loginUser}
                        handleModeChange={handleModeChange}
                        handleLogout={handleLogout}
                        theme={theme} />
                </Box>

                </Toolbar>
                
            </AppBar>
        </Box>
    );
}