import React, { useEffect, useState } from 'react'
import { Button, Card } from 'antd'
import { Avatar, List, notification } from 'antd';
import { callFlashBackend, flashDataFetcher } from '../../../../../Utils/flashHelpers';
import { useParams } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";

import TextArea from 'antd/es/input/TextArea';
import { Box, Paper, Typography } from '@mui/material';
import { timeAgo } from '../../../../../Utils/flashHelpers';

export default function Comments({commentsData, loadCommentsData, loading, request_uuid}) {
    let id  = request_uuid

    const [comment, setComment] = useState('')
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();
    const [api, contextHolder] = notification.useNotification();
    const [addingComment, setAddingComment] = useState(false)

    useEffect(() => {
        handleTabChange('', 'view-requests');
        document.title = "View Requests";
    }, []);


    // console.log(commentsData)


    useEffect(() => {
        loadCommentsData()
    }, [])

    function onChange(value) {
        setComment(value)
    }

    async function addComment() {
        setAddingComment(true)

        if (comment.length < 4) {
            api.error({
                message: 'Error Happened! Failed to add comment',
                description: `Comment must be greater then 4 characters`,
            });
            setAddingComment(false)

            return
        }

        let data = {
            "request_uuid": id,
            "comment": comment,
            "commented_by": loginUser.user_email,
            "key": "WIZARD-0"
        }

        callFlashBackend(`comments/STORK`, "POST", JSON.stringify(data))
            .then(
               
                (result) => {
                    if (result.status == "success") {
                        loadCommentsData()
                        api.success({
                            message: 'Comment Added',
                            description: `Yayy! Comment has been added successfully!`,
                        });
                        setAddingComment(false)
                        return result;
                    } else {
                        setAddingComment(false)
                        api.error({
                            message: 'Error Happened! Failed to add comment',
                            description: `${data.message}`,
                        });
                    
                    }
                }
            ).catch((error) => {
                if (error.toString().toLowerCase().includes('too many requests')){
                    api.error({
                        message: 'Too Many Requests',
                        description: `Please, wait for some more seconds!`,
                    });
                }
                setAddingComment(false)
            })
    }
  return (
    <Card
    title="Activity"
>
    {contextHolder}
    <Box sx={{ mb: 3 }}>
        <TextArea

            showCount
            // maxLength={100}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Comment"
            style={{
                height: 80,
                resize: 'none',
            }}
        />
        <Box sx={{ mt: 2, mb: 5, textAlign: 'right' }}>
            <Button loading={addingComment} onClick={() => addComment()} style={{ marginTop: 8 }} type='primary'>Comment</Button>
        </Box>
    </Box>

    <List
        itemLayout="horizontal"
        dataSource={commentsData ? commentsData : []}
        loading={loading}
        renderItem={(item, index) => (
            <Paper sx={{p:1, mt:2}} variant='outlined'>
                <List.Item>
                <List.Item.Meta
                    // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                    title={<Typography sx={{ fontWeight: 500, fontSize: 14, color: '' }}>{item.commented_by}</Typography>}

                    description={
                        <>
                        <Typography sx={{ fontWeight: 500, fontSize: 12, color: '' }}>
                            {timeAgo(item.stamp_created)}
                        </Typography>
                        <Box sx={{mt:1,}}>
                        <Typography sx={{color: 'black', fontSize: 14}}>{item.comment}</Typography>
                        </Box>
                        </>
                    }
                />
            </List.Item>
            </Paper>
        )}
    />

</Card>
  )
}
