import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { Card, Divider } from 'antd'
import QuickViewUser, { QuickViewUserApprover } from '../../../Components/Global/QuickViewUser'
import { flashDataFetcher } from '../../../../../Utils/flashHelpers'

export default function InstantMessagingView({ requestData, parameters }) {

  return (
    <Card
    // title={"Request Details"}
    >

      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Request Raised For</Typography>
            <Box sx={{ mt: 1 }}>
              <QuickViewUser user_id={requestData?.parameters.DYNAMIC_REQUESTOR} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Selected Platform</Typography>
            <Box sx={{ mt: 1 }}>
               {requestData?.parameters.INSTANT_MESSAGING_TYPE ? requestData?.parameters.INSTANT_MESSAGING_TYPE : 'N/A'}
            </Box>
          </Grid>
         
        </Grid>
        <Divider />
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Start Date of Access</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.START_DATE} 
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>End Date of Access</Typography>
            <Box sx={{ mt: 1 }}>
              {requestData?.parameters.END_DATE}
            </Box>
            
          </Grid>
          
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>PII Confirmation</Typography>
            <Box sx={{ mt: 1 }}>
              {
                requestData?.parameters.PII_CONFIRMATION === "PII_YES" ?
                <Typography sx={{ fontSize: 14 }}><strong>Yes,</strong>I will be sharing PII / Sensitive Data over Instant Messaging</Typography> : <></>
              }
                            {
                requestData?.parameters.PII_CONFIRMATION === "PII_NO" ?
                <Typography  sx={{ fontSize: 14 }}><strong>No,</strong> I will not be sharing any PII/ Sensitive Data Over Instant Messaging</Typography> : <></>
              }
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Justification</Typography>
            <Box >
              {requestData?.parameters.JUSTIFICATION}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}
