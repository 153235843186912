import React, { useState, useEffect } from 'react'
import { Box, Container, Grid } from '@mui/material'
import { useOutletContext } from 'react-router-dom';
import useSWR from 'swr';
import { flashDataFetcher } from '../../../../Utils/flashHelpers';
import { Button, Flex, Spin } from 'antd';
import Filters from '../../../../Utils/Filters';
import TablePagination from '@mui/material/TablePagination';
import RequestCard from '../RequestCard';


export default function AwaitingApproval() {
    const [handleTabChange, handleTokenError, loginUser] = useOutletContext();

    const [activeTab, setActiveTab] = useState('AWAITING_APPROVAL')

    const [pagination, setPagination] = useState({
        count: 1,
        page: 0,
        limit: 0,
        rowsPerPage: 6,
        data: [],
        isLoading: true,
        isSearching: false
    });


    const [filters, setFilters] = useState({
        request_uuid: {
            type: 'text',
            value: '',
            operator: 'exact',
            allowed_operators: ['exact'],
        },
        requestor: {
            type: 'text',
            value: '',
            operator: 'exact',
            allowed_operators: ['exact'],
        },
        status: {
            type: 'text',
            value: '',
            operator: 'exact',
            allowed_operators: ['exact'],
        },
    })

    const baseURL = `transitions/assigned/STORK?assigned_to=${loginUser.user_email}&sort_order=desc`
    const requestorFilter = filters.requestor.value && `&requestor=${filters.requestor.value}`;
    const statusFilter = `&status=${activeTab}`;
    const requestUUID = filters.request_uuid.value && `&request_uuid=${filters.request_uuid.value}`;
    const urlPagination = `&skip=${pagination.page * pagination.rowsPerPage}&limit=${pagination.rowsPerPage}`;
    const url = baseURL + statusFilter + requestorFilter + requestUUID + urlPagination

    const { data: requests, error: requestsErorrs, mutate: refreshRequestsData } = useSWR(url, flashDataFetcher);


    useEffect(() => {
        if (requests) {
            if (requests.status == "success") {
                setPagination({
                    ...pagination,
                    count: (requests && requests.pagination.total) || 1,
                    data: !pagination.isSearching ? [...pagination.data, ...requests.data] : requests.data,
                    isLoading: false
                });
            } else {
                setPagination({
                    ...pagination,
                    isLoading: false
                });
            }
        }
    }, [requests, activeTab])

    const handleFilterUpdate = (filterType, value, key = 'value') => {
        let update = { ...filters };
        if (key === 'value') {
            if (filterType === 'tags') {
                update[filterType].value = value.toUpperCase();
            } else {
                update[filterType].value = value;
            }
        } else if (key === 'operator') {
            update[filterType].operator = value;
        }

        setFilters(update);

        setPagination({
            ...pagination,
            page: 0,
            rowsPerPage: 6,
            isSearching: true
        });
    }

    function ChangeStatusType(status) {
        setActiveTab(status)
        setPagination({
            ...pagination,
            isLoading: true,
            isSearching: true,
            page: 0,
            limit:0,
            rowsPerPage: 6
            // page: page
        });
    }

    const changeRowsPerPage = (event) => {
        let newRows = parseInt(event.target.value, 10);
        setPagination({
          ...pagination,
          isLoading: true,
          rowsPerPage: newRows,
          isSearching: true
        });
    };


    const handleChangePage = (event, newPage) => {
        setPagination({
            ...pagination,
            isLoading: true,
            page: newPage
        });
        // setPage(newPage);
    };

    return (
        <Box>
            <Box sx={{ mb: 4 }}>

                <Box>
                    <Box sx={{ mb: 6 }}>
                        <Filters
                            filters={filters}
                            handleFilterUpdate={handleFilterUpdate}
                        />
                    </Box>
                </Box>

                <Flex gap="small" align="flex-start" vertical>
                    <Flex gap="small" wrap="wrap">

                        <Button
                            onClick={() => ChangeStatusType("AWAITING_APPROVAL")}
                            type={activeTab === 'AWAITING_APPROVAL' ? 'primary' : 'default'}
                            size={"medium"}
                        >
                            <strong>AWAITING APPROVAL</strong>
                        </Button>

                        <Button
                            onClick={() => ChangeStatusType("APPROVED")}
                            type={activeTab === 'APPROVED' ? 'primary' : 'default'}
                            size={"medium"}
                        ><strong>APPROVED</strong>
                        </Button>

                        <Button
                            type={activeTab === 'REJECTED' ? 'primary' : 'default'}
                            onClick={() => ChangeStatusType("REJECTED")} size={"medium"}
                        ><strong>REJECTED</strong>
                        </Button>

                    </Flex>
                </Flex>
            </Box>
            {
             pagination.isLoading ? <Container sx={{textAlign:'center', alignItems:'center', alignContent:'center', mt: 6}}> <Spin /></Container> :
             <>
                <Grid container spacing={4}>
                  {
                        pagination.data.map((request, index) => { return <Grid item xs={12} md={12} lg={6} xl={4} key={index}><RequestCard current_tab={'approvals'} request={request} /></Grid> })
                  }
                </Grid>
              <Box sx={{mt:2, mb:3}}>
                {
                    pagination.data.length > 1 ? 
                    <TablePagination
                        component="div"
                        count={pagination.count}
                        page={pagination.page} 
                        rowsPerPage={pagination.rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={changeRowsPerPage}
                        /> :<></>
                }
              </Box>
            </>
           }
        </Box>
    )
}
