import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { flashDataFetcher } from '../../../../../Utils/flashHelpers';
import { Box, Typography, Paper, Grid, ListItem, ListItemText, List } from '@mui/material';
import { Timeline, theme, Card, Alert, Tag, Badge, Divider } from 'antd';
import { Backdrop, CircularProgress } from '@mui/material';
import QuickViewUser, { QuickViewUserApprover } from '../../../Components/Global/QuickViewUser';


export default function TransitionsList({approversMatrix, getApproversMatrix,isApproverMatrix }) {
    return (
        <Box>
            {
                isApproverMatrix ? <Timeline
                    items={ListBuilder(approversMatrix)}
                /> : <></>
            }
        </Box>
    )
}

function ListBuilder(result) {
    let data_builder = []

    result.map((result, index) => data_builder.push({
        color: result.status === "APPROVED" ? 'green' : "blue",
        children: (
            <>
                <Paper
                    variant='outlined'
                    sx={{ p: 1 }}
                >
                    <Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 600, fontSize: 14, mb: 0.5 }}>
                                    {
                                        result.stage_name[0] ? result.stage_name[0] : result.stage_name[1] ? result.stage_name[1] : 'Stage'
                                    }
                                </Typography>
                                <Grid item xs={12}>
                                    <Box sx={{ textAlign: 'left', mt: 0, mb: 1 }}>
                                        <TransitionLabels sx={{ ml: 2 }} status={result?.status ? result.status : 'ERROR'} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <List
                            bordered
                            sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {
                                result?.assigned_to?.map((assigned) => {
                                    return (
                                        <>
                                            <ListItem
                                                // key={result.assigned_to}
                                                disableGutters
                                            >
                                                <ListItemText sx={{ my: 0, }}
                                                    primary={
                                                        <>
                                                            <Paper sx={{ p: 1 }} variant='outlined'>
                                                                <QuickViewUserApprover email_id={assigned} />
                                                            </Paper>

                                                        </>
                                                    }
                                                />
                                            </ListItem>

                                        </>
                                    )
                                })
                            }
                        </List>
                    </Box>
                </Paper>
            </>
        ),
    }))

    return data_builder
}


function TransitionLabels({ status }) {

    let color = "default"
    let new_status = status

    if (status === "APPROVED") {
        color = "success"
    } else if (status === "AWAITING_APPROVAL") {
        color = "processing"
    } else if (status === "REQUEST_REJECTED" || status === "REJECTED" || status === "REQUEST_CANCELLED" || status === "REQUEST_FAILED") {
        color = "error"
    } else if (status === "AWAITING_PREVIOUS_STAGE_APPROVAL") {
        new_status = "PENDING"
        color = "warning"
    }

    return <Tag size="small" color={color}>
        <Typography sx={{ fontSize: 12, fontWeight: 600 }}>{status && status !== '' ? new_status : 'something went wrong!'}</Typography>
    </Tag>
}



