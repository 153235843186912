import React from 'react';

import Dashboard from '../V2/Dashboard';

// import Dashboard from "../Components/Dashboard";

import MainLayout from "../Layouts/MainLayout";
import { TaskAlt, DashboardOutlined, PersonSearch, ManageAccounts } from "@mui/icons-material";
import FormRouter from '../V2/Requests/ComponentsListView/FormRouter';
import ViewRequest from '../V2/Requests/View/RequestView';
import SearchIcon from '@mui/icons-material/Search';
import TelegramIcon from '@mui/icons-material/Telegram';
import UserManagement from '../V2/View/index';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CreateRequests from '../V2/Requests/ComponentsListView';
import ViewRequests from '../V2/Requests/View';

export const adminNavigationItems = {
    "Dashboard": {
        "home": {
            title: "Dashboard",
            link: "/",
            icon: <DashboardOutlined />,
            element: <Dashboard />
        },
    },
    "Requests": {
        // "create-request":{
        //     title: "Raise a Request",
        //     link: "create-request",
        //     icon: <TelegramIcon />,
        //     element: <CreateRequests />
        // },
        "view-requests": {
            title: "View Requests",
            link: "requests",
            icon: <SearchIcon />,
            element: <ViewRequests/>
            // ViewRequests
        }
    },
    // "User Management": {
    //     "view-users": {
    //         title: "View Users",
    //         link: "users",
    //         icon: <PeopleOutlineIcon />,
    //         element: <UserManagement />
    //         // ViewRequests
    //     }
    // },

}

const children = Object.values(adminNavigationItems).map(
    group => Object.values(group).map(
        item => ({
            path: item.link,
            element: item.element
        })
    )
).flat();


const hiddenChildren = [
    {
        path: '/create-request/:form',
        element: <FormRouter />   
    },
    {
        path: '/request/view/:id',
        element: <ViewRequest />
    },
]


export const AdminRoutes = (loginUser, handleLogout, handleModeChange, handleTokenError) => ({
    path: '/',
    element: <MainLayout
                loginUser={loginUser}
                handleModeChange={handleModeChange}
                handleLogout={handleLogout}
                handleTokenError={handleTokenError} />,
    children: children.concat(hiddenChildren)
});
